import { withInstrumentation } from "@amzn/asx-ui-telemetry-client";

import apiClient from "./apiClient";
import Logger from "./logger";
import { telemetryClient } from "./telemetry/client";
import { OPS_METRICS } from "./telemetry/constants";

export const getTemplates = withInstrumentation(telemetryClient, OPS_METRICS.OPERATIONS.GetTemplates, async () => {
  try {
    const res = await apiClient.get("/templates?size=10000", {});
    return res?.data?.results;
  } catch (err) {
    Logger.sessionError(`error getting templates ${err}`);
    return err;
  }
});
