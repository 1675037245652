import { create } from "zustand";
import { setIndexedDb } from "./persistantStore";
import { INDEXED_DB_STORE } from "./util/constants";

const {
  feedbacksList: idbFbList,
  lastUpdatedTimeAllFeedback: idbUpdateTimeAllFb,
  myFeedbacksList: idbMyFbList,
  lastUpdatedTimeMyFeedback: idbUpdateTimeMyFb,
} = INDEXED_DB_STORE;

export const useStore = create(set => ({
  userPermissions: {},
  setUserPermissions: permissions => set(() => ({ userPermissions: permissions })),
  staticData: [],
  updateStaticData: staticData => set(() => ({ staticData: staticData })),
  userInfo: {
    user: undefined,
    token: undefined,
  },
  setUserInfo: userInfo => set(() => ({ userInfo: userInfo })),
  feedbacksList: [],
  twoYearLength: 0,
  setTwoYearLength: count => set(() => ({ twoYearLength: count })),
  lastUpdatedTimeAllFeedback: "",
  setlastUpdatedTimeAllFeedback: time => {
    setIndexedDb(idbUpdateTimeAllFb, time);
    set(() => ({ lastUpdatedTimeAllFeedback: time }));
  },
  updateFeedbackList: feedbacksList => {
    setIndexedDb(idbFbList, feedbacksList);
    set(() => ({ feedbacksList: feedbacksList }));
  },
  myFeedbacksList: [],
  lastUpdatedTimeMyFeedback: "",
  setlastUpdatedTimeMyFeedback: time => {
    setIndexedDb(idbUpdateTimeMyFb, time);
    set(() => ({ lastUpdatedTimeMyFeedback: time }));
  },
  updateMyFeedbackList: myFeedbacksList => {
    setIndexedDb(idbMyFbList, myFeedbacksList);
    set(() => ({ myFeedbacksList: myFeedbacksList }));
  },
}));
