import { useHistory } from "react-router";
import { Modal } from "@amzn/storm-ui";
import CreateFeedbackThemesform from "./CreateFeedbackThemesform";

const FeedbackThemeModalWrapper = props => {
  const { mode } = props;
  const { closeEditModal } = props;
  const history = useHistory();

  const closeCreateModal = () => {
    history.push("/themes");
  };

  const closeEditedModal = () => {
    closeEditModal(false);
  };

  return (
    <Modal isOpen={true} header={`${mode} theme`} onClose={mode === "Edit" ? closeEditedModal : closeCreateModal}>
      <CreateFeedbackThemesform {...props} changeApplyButtonDisabledProperty={() => {}} editedPayload={() => {}} />
    </Modal>
  );
};

export default FeedbackThemeModalWrapper;
