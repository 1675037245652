import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Divider, Button, TextButton, TextAreaFormGroup, InlineMessage } from "@amzn/storm-ui";
import { Dropdown, DropdownItem } from "@amzn/storm-ui";
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { useStore } from "../../../store";
import apiClient from "../../../util/apiClient";
import Logger from "../../../util/logger";
import Row from "../../../components/row";
import { createEMFObject, overrideZIndex, stringComparatorForArrayOfObjects } from "../../../util/util";
import { ThemeProvider } from "styled-components";
import ErrorAlert from "../../../components/error-alert/ErrorAlert";

const DeleteCategorizationForm = props => {
  const [entity, setEntity] = useState("");
  const [isError, setIsError] = useState(false);
  const [staticItemList, setStaticItemList] = useState([]);
  const [selectedItemTodelete, setSelectedItemTodelete] = useState({});
  const [notes, setNotes] = useState("");
  const entities = ["Feedback type", "Feedback category", "Product/Services"];

  const staticData = useStore(state => state.staticData);

  const userInfo = useStore(state => state.userInfo);
  const history = useHistory();
  const { updateStaticData } = useStore();

  useEffect(() => {
    let fetchedStaticItemList = [];
    if (staticData) {
      if (entity === "Feedback type") {
        fetchedStaticItemList = staticData.filter(data => data.docType === "FEEDBACK_TYPE");
      } else if (entity === "Feedback category") {
        fetchedStaticItemList = staticData.filter(data => data.docType === "FEEDBACK_CATEGORY");
      } else if (entity === "Product/Services") {
        fetchedStaticItemList = staticData.filter(data => data.docType === "PRODUCT_SERVICES");
      }
    }
    setStaticItemList(fetchedStaticItemList.sort(stringComparatorForArrayOfObjects));
    // eslint-disable-next-line
  }, [entity]);

  const closeCreateModal = () => {
    history.push("/admin/categorization");
  };

  const handleDelete = e => {
    let submitObj = {};

    const {
      user: { email },
    } = userInfo;
    submitObj = {
      entityType:
        entity === "Product/Services"
          ? "productServices"
          : entity === "Feedback type"
            ? "feedbackType"
            : "feedbackCategory",
      operationType: "delete",
      input: {
        id: selectedItemTodelete.id,
        fields: {
          docType:
            entity === "Product/Services"
              ? "PRODUCT_SERVICES"
              : entity === "Feedback type"
                ? "FEEDBACK_TYPE"
                : "FEEDBACK_CATEGORY",
          parent: selectedItemTodelete.parent,
          value: selectedItemTodelete.value,
          updatedBy: email,
          notes: notes,
          operationType: "delete",
        },
      },
    };
    apiClient
      .post("/staticoperations", submitObj)
      .then(deleteSuccess)
      .catch(err => {
        Logger.sessionError(`Failed to Delete ${entity}: ${selectedItemTodelete.value} err:${err}`, {
          emfLog: createEMFObject(`DeleteOpsForAdminFailed`, 1, "Count", {
            DeleteCategorization: "DeleteCategorizationCounts",
          }),
        });
        setIsError(true);
      });
  };

  const deleteSuccess = () => {
    Logger.sessionInfo(`Delete ${entity} successfull: ${selectedItemTodelete.value}`, {
      emfLog: createEMFObject("DeleteCategorizationCounts", 1, "Count"),
    });
    const params = {};
    apiClient
      .get("/static?size=10000", { params })
      .then(response => {
        updateStaticData(
          response?.data?.results?.map(stat => {
            return Object.assign(stat.fields, { id: stat.id });
          })
        );
        history.push("/admin/categorization");
      })
      .catch(error => {
        Logger.sessionError(`fetching staticdata failed ${error}`, {
          browserUrl: window.location.href,
        });
        setIsError(true);
      });
  };

  if (isError) {
    return <ErrorAlert componentName="DeleteCategorisationForm" />;
  }

  return (
    <ThemeProvider theme={overrideZIndex}>
      <Dropdown
        onChange={val => setEntity(val)}
        selectedValue={entity}
        onOverrideLabel={(dropdownItem, value) => {
          if (value) {
            return dropdownItem;
          }
          return "Choose entity";
        }}
      >
        {entities.map((entityItem, key) => (
          <DropdownItem key={key} value={entityItem}>
            {entityItem}
          </DropdownItem>
        ))}
      </Dropdown>
      <Divider />

      {entity && staticItemList.length > 0 && (
        <Form id="createLabel">
          <FormRow
            label={entity}
            isSectionHeader
            id="remarks-row"
            controlComponents={[
              componentId => (
                <Dropdown
                  onChange={entityItem => setSelectedItemTodelete(entityItem)}
                  selectedValue={selectedItemTodelete}
                  onOverrideLabel={(dropdownItem, value) => {
                    if (value && dropdownItem) {
                      return dropdownItem;
                    }
                    return `Choose ${entity.toLowerCase()} to delete`;
                  }}
                  error={!selectedItemTodelete?.value}
                >
                  {staticItemList.map((entityItem, key) => (
                    <DropdownItem key={key} value={entityItem}>
                      {entityItem.value}
                    </DropdownItem>
                  ))}
                </Dropdown>
              ),
            ]}
          />

          <FormRow
            label="Remarks"
            isSectionHeader
            id="remarks-row"
            controlComponents={[
              componentId => (
                <TextAreaFormGroup
                  fulwidth={true}
                  placeholder="Remarks"
                  value={notes}
                  onChange={event => {
                    setNotes(event.target.value);
                  }}
                  statusType={selectedItemTodelete?.value && !notes ? "error" : undefined}
                />
              ),
            ]}
          />
          {selectedItemTodelete && notes && (
            <InlineMessage
              messageType="warning"
              message="The Action is irreversible. Deleting an entity will remove it from the associated feedbacks too."
            />
          )}
          <Divider />
          <Row alignmentHorizontal="right">
            <TextButton onClick={closeCreateModal}>Cancel</TextButton>
            <Button disabled={!selectedItemTodelete || !notes} primary={true} onClick={handleDelete}>
              {"Proceed"}
            </Button>
          </Row>
          {/* <Divider/> */}
        </Form>
      )}
    </ThemeProvider>
  );
};

export default DeleteCategorizationForm;
