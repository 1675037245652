import * as React from "react";
import { withRouter } from "react-router-dom";
import Column from "../../components/column";
import "./VectorWidget.scss";
import "../../styles/common.scss";
import Logger from "../../util/logger";
import { createEMFObject } from "../../util/util";

import { Text, Link, Card } from "@amzn/storm-ui";
import UserContext from "../../context/userContext";

class VectorWidget extends React.Component {
  componentDidMount() {
    Logger.sessionInfo("ACFWidget requested", {
      emfLog: createEMFObject("ACFPortalWidgetRequests", 1, "Count"),
    });
  }

  render() {
    return (
      <Card padding={"base"}>
        <Column alignmentHorizontal={"center"}>
          <Text type="h2">Closed Loop Feedback Now In Salesforce</Text>
          <Text>
            “Closed Loop Feedback in Vector will be deprecated by November 22, 2024. Click to access Closed Loop
            Feedback in Salesforce:{" "}
            <Link
              href="https://ams-amazon.lightning.force.com/lightning/cmp/c__CustomerFeedbackAura"
              external
              target="_blank"
              rel="noreferrer nofollow"
            >
              here
            </Link>
          </Text>
          <Text>
            Or click here to request Salesforce access:{" "}
            <Link
              href="https://w.amazon.com/bin/view/AdSales/SE_and_I/SPEO/AdsGSO/Home/Sales_Technology/Home/SPOT/AccessCheck/"
              external
              target="_blank"
              rel="noreferrer nofollow"
            >
              wiki
            </Link>
          </Text>
        </Column>
      </Card>
    );
  }
}
VectorWidget.contextType = UserContext;
export default withRouter(VectorWidget);
