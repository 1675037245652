import * as React from "react";
import { useState } from "react";
import { Extender } from "@amzn/storm-ui";
import { CHARACTER_LIMIT } from "../../util/constants";

const MoreTextInCell = props => {
  const { value, column } = props;
  const [isOpen, setIsOpen] = useState(false);
  if (!value) {
    return <text></text>;
  }
  const characterLimit = column?.colDef?.characterLimit ? column.colDef.characterLimit : CHARACTER_LIMIT;
  return (
    <text>
      {value.substr(0, characterLimit)}
      {value.length > characterLimit && (
        <Extender label={isOpen ? "less" : "more"} handleClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          {value.substr(characterLimit)}
        </Extender>
      )}
    </text>
  );
};

export default MoreTextInCell;
