import { ERRORS } from "@amzn/asx-ui-telemetry-client";

/**
 * * Operations - Async operations. Each Operation has an associated metric for each measurement: Time, Success, Fault, Count
 */
const OPERATIONS = {
  GetTemplates: "API.getTemplates",
};

const TIMERS = {
  PageLoadToAppLoad: "PageLoadToAppLoad",
};

export const OPS_METRICS = {
  TIMERS,
  OPERATIONS,
  ERRORS,
} as const;

export { OPS_METRICS_TYPES } from "@amzn/asx-ui-telemetry-client";
