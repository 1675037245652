import React from "react";
import { withRouter } from "react-router-dom";
import Column from "../../../components/column";
import { PieChart, getPieLegendNameValueLabelData } from "@amzn/unified-chart-components/pack";
import Loader from "../../../components/Loader";
import { Text } from "@amzn/storm-ui";
import AdaptiveHelpTip from "@amzn/storm-ui-adaptive-helptip";
import Row from "../../../components/row";

class TopCategory extends React.Component {
  render() {
    const {
      helpText,
      topCategoriesData: { loadingState, xData, yData },
    } = this.props;
    if (!xData || !yData) {
      return <Loader />;
    }
    return (
      <Column width="100%">
        <Row spacing="100">
          <h3>Top 5 Feedback Categories</h3>
          <AdaptiveHelpTip>
            <Text>{helpText}</Text>
          </AdaptiveHelpTip>
        </Row>
        <PieChart
          metrics={[
            {
              plotDataType: "PIE",
              xData: xData,
              yData: yData,
              formatType: "PERCENTAGE",
              id: "topCategories",
              title: "Top Categories",
              hintTitle: "Top Categories",
              hintText: "Most Frequent Categories",
              shortTitle: "Top Categories",
            },
          ]}
          // domain={{ type: DomainType.GENERIC }}
          locale="en-US"
          getLegendLabels={getPieLegendNameValueLabelData}
          loadingState={loadingState}
        />
      </Column>
    );
  }
}

export default withRouter(TopCategory);
