import uniq from "lodash/uniq";
import set from "lodash/set";
import { OPS_METRICS_TYPES } from "./constants";
import { logger as logLevelLogger } from "./logger";
import {
  Dimensions,
  Logger,
  MetricsConfig,
  TelemetryClientConstructorParams,
  TelemetryClientInterface,
  TelemetryPlugin,
} from "./types";
import { getObfuscatedUserId } from "./utilities/get-obfuscated-user-id";
/**
 * Helper class that provides a wrapper to the metrics provider and logger and utility functions for telemetry operations.
 */
export class TelemetryClient implements TelemetryClientInterface {
  private metricsProviders: TelemetryClientConstructorParams["metrics"];
  private logger: Logger;
  private timers: ReturnType<TelemetryClientInterface["getTimers"]> = new Map();
  public config: MetricsConfig;
  constructor({
    metrics,
    logger = logLevelLogger,
    config,
  }: TelemetryClientConstructorParams) {
    this.logger = logger;
    this.metricsProviders = metrics;
    this.config = config;
    if (!this.config.isAnonymous) {
      this.addObfuscatedUserIdToSharedContext();
    }
  }

  public getLogger = (tag: string) => this.logger.getLogger(tag);

  getTimers = () => this.timers;

  startTimer = (operation: string, dimensions?: Dimensions) => {
    if (this.timers.has(operation)) {
      this.logger.warn(
        `Timer ${operation} was already started. Aborting previous timer and starting a new one`
      );
    }
    this.timers.set(operation, { value: performance.now(), dimensions });
  };

  stopTimer = (
    operation: string,
    additionalParams?: { dimensions?: Dimensions; newOperationName?: string }
  ): number => {
    const { newOperationName, dimensions: additionalDimensions } =
      additionalParams ?? {};
    const now = performance.now();
    const timer = this.timers.get(operation);
    if (!timer) {
      this.logger.warn(
        `Timer ${operation} was not started. Ignoring stopTimer request`
      );
      return 0;
    }
    const { value: startTime, dimensions } = timer;
    const metricsDimensions =
      dimensions || additionalDimensions
        ? {
            ...this.config.sharedContext,
            ...dimensions,
            ...additionalDimensions,
          }
        : undefined;
    // Remove scope from ongoing timers map
    this.timers.delete(operation);
    const time = now - startTime;
    this.publishTimer(
      newOperationName ? newOperationName : operation,
      time,
      metricsDimensions
    );
    return time;
  };

  abortTimer = (operation: string) => {
    if (!this.timers.has(operation)) {
      this.logger.warn(
        `Timer ${operation} was not started. Ignoring abortTimer request`
      );
      return;
    }
    this.timers.delete(operation);
  };

  publishCounter = (
    operation: string,
    metricName: string,
    count: number,
    dimensions?: Dimensions
  ) => {
    for (const metrics of this.metricsProviders) {
      metrics.provider.publishCounter(operation, metricName, count, {
        ...this.config.sharedContext,
        ...dimensions,
      });
    }
  };

  publishTimer = (operation: string, time: number, dimensions?: Dimensions) => {
    for (const metrics of this.metricsProviders) {
      metrics.provider.publishTimer(operation, OPS_METRICS_TYPES.Time, time, {
        ...this.config.sharedContext,
        ...dimensions,
      });
    }
  };

  publishPageView = (pageId: string, pageAttributes?: Dimensions) => {
    for (const metrics of this.metricsProviders) {
      metrics.provider.publishPageView(pageId, {
        ...this.config.sharedContext,
        ...pageAttributes,
      });
    }
  };

  public start = () => {
    const allPlugins: TelemetryPlugin[] = this.metricsProviders
      .flatMap((provider) => provider.plugins)
      .filter((plugin): plugin is TelemetryPlugin => Boolean(plugin));
    const plugins = uniq(allPlugins) ?? [];
    for (const plugin of plugins) {
      plugin(this);
    }
  };

  /**
   * Retrieve the user_id from takt and adds it to the shared context if it's available
   */
  private addObfuscatedUserIdToSharedContext = async () => {
    const userId = await getObfuscatedUserId();
    set(this.config, "sharedContext.user_id", userId);
  };
}
