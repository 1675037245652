import { whenAmazonTakt } from "@amzn/takt-utils";

/**
 * Retrieves the obfuscated user id using Takt.
 * This value is available on the AmazonTakt object in both ATC and AMH.
 * If Takt is not available on the page or if it doesn't have a user_id, the promise will not resolve to conform with whenAmazonTakt's API.
 */
export const getObfuscatedUserId = () => {
  return new Promise<string>((resolve) => {
    whenAmazonTakt((AmazonTakt) => {
      if (AmazonTakt.user_id) {
        resolve(AmazonTakt.user_id);
      }
    });
  });
};
