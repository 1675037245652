import { getThemebyId, bulkUpdateFeedback, createOverrideMappingCase, createFeedbackThemeMapping } from "./apiUtils";
import Logger from "./logger";
import { verifyReviewerRights } from "./util";

export const createMappingFlow = async (
  selectedThemeId,
  selectedThemeName = "",
  themeBlockedRevenue,
  feedbackThemeDescription,
  selectedThemePriority,
  selectedRowItems,
  user,
  detagMappingSubmitArr,
  detagSubmitTheme,
  overrideReason = ""
) => {
  const childFeedbackIds = selectedRowItems.reduce(
    (accumulator, currentValue) => accumulator + currentValue.rowNodeId + ",",
    ""
  );
  console.log(childFeedbackIds.slice(0, -1));
  let mappingSubmitObj = {};
  mappingSubmitObj = {
    fields: {
      themeId: selectedThemeId,
      feedbackIds: childFeedbackIds.slice(0, -1),
      action: "add",
    },
  };
  //create Mapping
  // const mapping = await createFeedbackThemeMapping(submitObj)
  // Logger.sessionInfo(`created mapping successfully ${mapping}`)

  //update status in feedback
  const themeData = await getThemebyId(encodeURIComponent(selectedThemeId));
  const {
    data: {
      theme: {
        fields: { themeStatus, source, updatedSource },
      },
    },
  } = themeData;
  // await updateStatusAndParentTheme(selectedRowItems, themeStatus, selectedThemeId, selectedThemeName, user, mappingSubmitObj);
  let mLModelMapping = false;
  if (updatedSource === "dataScience" || source === "dataScience") {
    mLModelMapping = true;
  }
  //update total blocked revenue in theme
  let totalBlockedRevenue = selectedRowItems.reduce(
    (accumulator, currentValue) => Number(accumulator) + Number(currentValue.rowData.revenueImpacted),
    0
  );
  // totalBlockedRevenue += Number(fields.blockedRevenue)

  const updateThemeObj = {
    id: selectedThemeId,
    input: {
      fields: {
        blockedRevenue: `${totalBlockedRevenue}`,
        blockedRevAggregation: "add",
      },
    },
  };
  const submitThemeObj = {
    input: [updateThemeObj],
  };
  // await bulkUpdateTheme(submitThemeObj, selectedThemeId);
  await updateStatusAndParentTheme(
    selectedRowItems,
    themeStatus,
    selectedThemeId,
    selectedThemeName,
    themeBlockedRevenue,
    feedbackThemeDescription,
    selectedThemePriority,
    user,
    [mappingSubmitObj],
    submitThemeObj,
    detagMappingSubmitArr,
    detagSubmitTheme,
    mLModelMapping,
    overrideReason
  );
  Logger.sessionInfo(`updated theme blockedRevenue successfully ${selectedThemeId}`);
};

export const removeMappingFlow = (feedbackList, user) => {
  //remove Mapping
  const parentThemeFeedbackListMap = new Map();
  feedbackList.forEach(feedback => {
    const {
      rowData: { parentTheme, id },
    } = feedback;
    if (parentThemeFeedbackListMap.has(parentTheme)) {
      const fbacks = parentThemeFeedbackListMap.get(parentTheme);
      parentThemeFeedbackListMap.set(parentTheme, fbacks + "," + id);
    } else {
      parentThemeFeedbackListMap.set(parentTheme, id);
    }
  });
  const mappingSubmitArr = [];
  for (const [themeId, feedbacks] of parentThemeFeedbackListMap) {
    const submitObj = {
      fields: {
        themeId,
        feedbackIds: feedbacks,
        action: "remove",
      },
    };
    mappingSubmitArr.push(submitObj);
    // const mapping = await createFeedbackThemeMapping(submitObj)
    // Logger.sessionInfo(`created mapping successfully ${mapping}`)
  }

  //update status and parentTheme in feedback
  // await updateStatusAndParentTheme(feedbackList, "", "", "", user);

  //update blockedRevenue in theme
  const parentThemeBlockedRevenueMap = new Map();
  feedbackList.forEach(feedback => {
    const {
      rowData: { parentTheme, revenueImpacted },
    } = feedback;
    if (parentThemeBlockedRevenueMap.has(parentTheme)) {
      const totalBlockedRevenue = parentThemeBlockedRevenueMap.get(parentTheme);
      parentThemeBlockedRevenueMap.set(parentTheme, Number(totalBlockedRevenue) + Number(revenueImpacted));
    } else {
      parentThemeBlockedRevenueMap.set(parentTheme, Number(revenueImpacted));
    }
  });
  const updateThemeObjList = [];
  for (const [parentTheme, revenueImpacted] of parentThemeBlockedRevenueMap) {
    const updateThemeObj = {
      id: parentTheme,
      input: {
        fields: {
          blockedRevenue: `${revenueImpacted}`,
          blockedRevAggregation: "sub",
        },
        relations: null,
        type: "updateThemeBlockedRevenue",
      },
    };
    updateThemeObjList.push(updateThemeObj);
  }
  const submitTheme = {
    input: updateThemeObjList,
  };
  // await bulkUpdateTheme(submitTheme);
  return [mappingSubmitArr, submitTheme];
  // await updateStatusAndParentTheme(feedbackList, "", "", "", user, mappingSubmitArr, submitTheme);
  // Logger.sessionInfo(`updated theme mapping successfully`);
};

export const updateStatusAndParentTheme = async (
  selectedRowItems,
  status = "",
  selectedThemeId = "",
  selectedThemeName = "",
  themeBlockedRevenue = "0",
  feedbackThemeDescription = "",
  selectedThemePriority = "",
  user,
  mappingSubmitObj,
  submitThemeObj,
  detagMappingSubmitArr,
  detagSubmitTheme,
  mLModelMapping,
  overrideReason = ""
) => {
  const updateFeedbackPayloadList = [];
  let isFbUpdatedSourcedataScience = false;
  for (let i = 0; i < selectedRowItems.length; i++) {
    const feedback = selectedRowItems[i];
    const { rowData, rowNodeId } = feedback;
    if (rowData.updateSource === "dataScience") {
      isFbUpdatedSourcedataScience = true;
    }
    delete rowData.dates;
    rowData.revenueImpacted = `${rowData.revenueImpacted}`;
    rowData.opportunityValue = `${rowData.opportunityValue}`;
    const updateFbObj = {
      id: rowNodeId,
      input: {
        type: "updateFeedback",
        fields: {
          ...rowData,
          lastEditedBy: user.email,
          themeStatus: status,
          parentTheme: selectedThemeId,
          parentThemeName: selectedThemeName,
          themeBlockedRevenue: themeBlockedRevenue,
          feedbackThemeDescription: feedbackThemeDescription,
          themePriority: selectedThemePriority,
          themeAssociationDate: Math.floor(Date.now() / 1000).toString(),
          overrideReason: overrideReason,
        },
        relations: null,
      },
    };
    updateFeedbackPayloadList.push(updateFbObj);
  }
  const submitObj = {
    input: updateFeedbackPayloadList,
  };
  const CreateThemeMappingInput = {
    mappingInput: mappingSubmitObj,
    bulkFeedbackInput: submitObj,
    bulkUpdateThemeInput: submitThemeObj,
    detagMappingInput: detagMappingSubmitArr,
    detagBulkUpdateThemeInput: detagSubmitTheme,
  };
  //check if feedback or theme updateSource is ML then create override mapping request
  // else createFeedbackThemeMapping
  const isReviewer = await verifyReviewerRights();
  if (!isReviewer && (mLModelMapping || isFbUpdatedSourcedataScience)) {
    await createOverrideMappingCase(CreateThemeMappingInput);
    if (sessionStorage != null) {
      sessionStorage.setItem("isMappingApprovalCaseCreated", true);
    }
  } else {
    await createFeedbackThemeMapping(CreateThemeMappingInput);
  }
  Logger.sessionInfo(`Bulk updated Feedback status and parent successfully`);
};

export const updateFeedbackStatusAndParentTheme = async (
  selectedRowItems,
  status = "",
  selectedThemeId = "",
  selectedThemeName = "",
  user
) => {
  const updateFeedbackPayloadList = [];
  for (let i = 0; i < selectedRowItems.length; i++) {
    const feedback = selectedRowItems[i];
    const { rowData, rowNodeId } = feedback;
    delete rowData.dates;
    rowData.revenueImpacted = `${rowData.revenueImpacted}`;
    rowData.opportunityValue = `${rowData.opportunityValue}`;
    const updateFbObj = {
      id: rowNodeId,
      input: {
        type: "updateFeedback",
        fields: {
          ...rowData,
          lastEditedBy: user.email,
          themeStatus: status,
          parentTheme: selectedThemeId,
          parentThemeName: selectedThemeName,
        },
        relations: null,
      },
    };
    updateFeedbackPayloadList.push(updateFbObj);
  }
  const submitObj = {
    input: updateFeedbackPayloadList,
  };
  await bulkUpdateFeedback(submitObj);
  Logger.sessionInfo(`Bulk updated Feedback status and parent successfully`);
};
