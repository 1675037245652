import { COUNTERS, OPS_METRICS_TYPES } from "../constants";
import { MetricsConfig } from "../types";
import type { AwsRum } from "aws-rum-web";

export enum RumMethod {
  RecordPageView = "recordPageView",
  RecordEvent = "recordEvent",
}

export const clean = (str: string) => {
  return str.replace(/:/g, ".").replace(/\s/g, "");
};

type CloudWatchRumMetricsConfig = MetricsConfig & {
  rumClient?: AwsRum;
};

/**
 * A MetricsProvider class that publishes metrics using CloudWatch RUM
 * An instance of the RUM client exists on the window object under window.cwr
 */
export class CloudWatchRumProvider {
  params: CloudWatchRumMetricsConfig;
  constructor(params: CloudWatchRumMetricsConfig) {
    this.params = params;
  }

  publishCounter = (
    operation: string,
    metricName: string,
    count: number,
    dimensions?: Record<string, string>
  ) => {
    this.recordEvent(operation, metricName, count, dimensions);
  };

  publishTimer = (
    operation: string,
    metricName: string,
    time: number,
    dimensions?: Record<string, string>
  ) => {
    this.recordEvent(operation, metricName, time, dimensions);
  };

  publishPageView = (
    pageId: string,
    pageAttributes?: Record<string, string>,
    pageTags?: string[]
  ) => {
    const rumClient = this.getRumClient();
    if (!rumClient) return;

    const { sharedContext } = this.params;
    const allPageAttributes = {
      ...pageAttributes,
      ...sharedContext,
    };
    if (this.params.rumClient) {
      this.params.rumClient.recordPageView({
        pageId,
        pageAttributes: allPageAttributes,
        pageTags,
      });
      return;
    }
    rumClient(RumMethod.RecordPageView, {
      pageId: pageId,
      pageAttributes: allPageAttributes,
      pageTags,
    });
    this.publishCounter(COUNTERS.PageVisit, OPS_METRICS_TYPES.Count, 1, {
      pageId,
      ...pageAttributes,
    });
  };

  private recordEvent = (
    operation: string,
    metricName: string,
    value: number,
    dimensions?: Record<string, string>
  ) => {
    const eventName = this.createEventName(operation, metricName);
    const eventData = {
      value,
      ...dimensions,
    };
    if (this.params.rumClient) {
      // Rum Client was provided by consumer, using that instead of the one attached to Window
      this.params.rumClient.recordEvent(eventName, eventData);
      return;
    }
    const rumClient = this.getRumClient();
    if (!rumClient) return;
    rumClient(RumMethod.RecordEvent, {
      // Following convention documented here https://w.amazon.com/bin/view/SSPA/AX/ACPT/Telemetry/Documentation/CloudwatchRUMAccess#HLoggingNamingStandards
      type: eventName,
      data: eventData,
    });
  };

  private createEventName = (operation: string, metricName: string) => {
    const { siteName, serviceName } = this.params;
    return `com.amazon.ads.${clean(siteName)}.${clean(serviceName)}.${clean(
      operation
    )}.${clean(metricName)}`;
  };

  private getRumClient = (): any => {
    return "cwr" in window ? window.cwr : null;
  };
}
