import { logger } from './logger';
import { Dimensions, MetricsConfig, TelemetryClientInterface } from './types';

/**
 * Class implementing the TelemetryClient interface but that doesn't do anything.
 * This class can be used when the weblab for capturing operational metrics is turned off or for users who don't want to submit telemetry
 */
export class NoOpTelemetryClient implements TelemetryClientInterface {
    constructor() {}
    getTimers = () => new Map();
    startTimer = (operation: string) => {};
    stopTimer = (operation: string, additionalParams?: { dimensions?: Dimensions, newOperationName?: string }): number => {
        return 0;
    };
    abortTimer = (operation: string) => {};
    publishCounter = (operation: string, counterName: string, count: number) => {};
    publishTimer = (operation: string, time: number) => {};
    publishPageView: (pageId: string, pageAttributes?: Record<string, string>) => {};
    getLogger = () => {
        return logger;
    };
    start = () => {};
    config = {} as MetricsConfig
}
