import React, { useEffect } from "react";
import { Text, Card } from "@amzn/storm-ui";
import Column from "../../components/column";
import { useHistory } from "react-router";
import "./Admin.scss";
import { Page } from "../../typings/enum";
import { logPageMetric } from "../../util/logger";
import { useStore } from "../../store";
import { IS_ADMIN, IS_MAPPING_REVIEWER } from "../../util/constants";

const Admin = () => {
  const history = useHistory();
  const { userPermissions } = useStore();

  // const staticDataState = useStore((state) => state.staticData);

  useEffect(() => {
    logPageMetric(Page.AdminTab);
  }, []);

  const routeToAdminFeature = route => {
    history.push(route);
  };

  return (
    <React.Fragment>
      <Column spacingInset="500">
        <Text type="h3">Admin view</Text>
        <span>
          The admin view is an interface that allows users to bulk upload and create or edit feedback labels,
          categories, and templates. If you would like to grant administrative access to a new user, please reach out to
          @cngong.
        </span>
        {userPermissions[IS_ADMIN] && (
          <>
            <Card className="clickable" onClick={() => routeToAdminFeature("/admin/bulk-upload")}>
              {" "}
              Bulk upload{" "}
            </Card>
            <Card className="clickable" onClick={() => routeToAdminFeature("/admin/labels")}>
              {" "}
              Create/Edit feedback labels{" "}
            </Card>
            <Card className="clickable" onClick={() => routeToAdminFeature("/admin/template")}>
              {" "}
              Create/Edit feedback template{" "}
            </Card>
            <Card className="clickable" onClick={() => routeToAdminFeature("/admin/categorization")}>
              {" "}
              Create/Edit feedback categorization{" "}
            </Card>
          </>
        )}
        {userPermissions[IS_MAPPING_REVIEWER] && (
          <Card className="clickable" onClick={() => routeToAdminFeature("/admin/review-theme")}>
            {" "}
            Review feedback theme updates
          </Card>
        )}
      </Column>
    </React.Fragment>
  );
};

export default Admin;
