import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  ButtonGroup,
  ButtonGroupItem,
  Divider,
  Dropdown,
  DropdownItem,
  InputFormGroup,
  Text,
  TextAreaFormGroup,
  TextButton,
} from "@amzn/storm-ui";
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";

const SubmitRow = styled("div")`
  display: flex;
  justify-content: flex-end;
  button {
    margin-inline-start: 14px;
  }
`;

const Example = props => {
  const { appName } = props;
  const [campaignDescription, setCampaignDescription] = useState("");
  const [campaignName, setCampaignName] = useState(appName);
  const [portfolio, setPortfolio] = useState("Not happy");
  const [csatScore, setCsatScore] = useState("1");

  return (
    <>
      <Text type="h2" styleAs="h4">
        Create a feedback
      </Text>
      <Divider />
      <Form id="form-example">
        <FormRow
          // helpTipText="A campaign name is only visible to you, so choose a name that you can easily identify and refer back to later."
          label="Application name"
          labelTopSpacing="0px"
          id="form-row"
          optionalTag="Required"
          controlComponents={[
            componentId => (
              <>
                <InputFormGroup
                  id={componentId}
                  fullWidth
                  onChange={event => setCampaignName(event.target.value)}
                  placeholder="Your application name"
                  value={campaignName}
                />
              </>
            ),
          ]}
        />
        <FormRow
          helpTipText="Rank your customer's satisfaction based on the Feedback Category or Product/Service selected with 1 being 'extremely unsatisfied' and 5 being 'extremely satisfied' ."
          label="Customer Satisfaction Score"
          isSectionHeader
          id="csat-row"
          optionalTag="Required"
          controlComponents={[
            () => (
              <React.Fragment>
                <Text>How would you rate the customer's satisfaction?</Text>
                <Text>1-Extremely Unsatisfied to 5-Extremely Satisfied</Text>

                <ButtonGroup
                  name="device-type-filter"
                  onChange={value => {
                    // @ts-ignore
                    setCsatScore(value);
                  }}
                  selectedValue={csatScore}
                >
                  <ButtonGroupItem value="1">1</ButtonGroupItem>
                  <ButtonGroupItem value="2">2</ButtonGroupItem>
                  <ButtonGroupItem value="3">3</ButtonGroupItem>
                  <ButtonGroupItem value="4">4</ButtonGroupItem>
                  <ButtonGroupItem value="5">5</ButtonGroupItem>
                </ButtonGroup>
              </React.Fragment>
            ),
          ]}
        />
        <FormRow
          // helpTipText="A campaign description."
          label="Customer quote"
          labelTopSpacing="0px"
          id="form-row"
          optionalTag="Required"
          controlComponents={[
            componentId => (
              <>
                <TextAreaFormGroup
                  id={componentId}
                  fullWidth
                  onChange={event => setCampaignDescription(event.target.value)}
                  placeholder="Add additional information"
                  value={campaignDescription}
                />
              </>
            ),
          ]}
        />
        <FormRow
          label="Why is this important"
          labelTopSpacing="0"
          id="portfolio"
          // helpTipText="Portfolios are a group of campaigns that can have a budget cap. Portfolios enable you to group and organize campaigns."
          controlComponents={[
            componentId => (
              <>
                <Dropdown
                  id={componentId}
                  selectedValue={portfolio}
                  aria-describedby="portfolio-description"
                  onChange={value => setPortfolio(value)}
                >
                  <DropdownItem value="Not happy">Not happy</DropdownItem>
                  <DropdownItem value="portfolio-1">Portfolio 1</DropdownItem>
                  <DropdownItem value="portfolio-2">Portfolio 2</DropdownItem>
                  <DropdownItem value="portfolio-3">Portfolio 3</DropdownItem>
                  <DropdownItem value="portfolio-4">Portfolio 4</DropdownItem>
                </Dropdown>
              </>
            ),
          ]}
        />
        <SubmitRow>
          <TextButton type="button">Cancel</TextButton>
          <Button primary type="submit">
            Submit
          </Button>
        </SubmitRow>
      </Form>
    </>
  );
};

export default Example;
