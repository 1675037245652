import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import apiClient from "../../util/apiClient";
import Logger from "../../util/logger";
import { useLocation } from "react-router-dom";
import { Alert } from "@amzn/storm-ui";
import { downloadInChunk, createEMFObject } from "../../util/util";

const DownloadAttachments = () => {
  const location = useLocation();
  const { pathname } = location;
  const params = pathname.split("/");
  const fileName = params[3];
  const id = params[2];
  const [isdownloading, setIsDownloading] = useState(true);
  const [downloadFailure, setDownloadFailure] = useState(false);
  useEffect(() => {
    apiClient
      .get(`/download/${id}/${fileName}`)
      .then(res => {
        const {
          data: { preSignedUrl },
        } = res;
        fetch(preSignedUrl, {
          method: "Get",
        })
          .then(response => {
            downloadInChunk(response, fileName);
            setIsDownloading(false);
          })
          .catch(err => {
            Logger.sessionError(`error getting preSigned url for file ${fileName} for feedback ${id} ${err}`, {
              emfLog: createEMFObject("AttachmentDownloadFailure", 1, "Count"),
            });
            setIsDownloading(false);
            setDownloadFailure(true);
          });
      })
      .catch(err => {
        Logger.sessionError(`error getting preSigned url for file ${fileName} for feedback ${id} ${err}`);
        setIsDownloading(false);
        setDownloadFailure(true);
      });
    // eslint-disable-next-line
  }, []);

  if (isdownloading) {
    return (
      <Alert type="info">
        {" "}
        <Loader /> Downloading{" "}
      </Alert>
    );
  } else if (downloadFailure) {
    return <Alert type="error">Download failed</Alert>;
  } else {
    return <Alert type="success">Download complete</Alert>;
  }
};

export default DownloadAttachments;
