const endpoints = {
  staticOperations: "/staticoperations",
  template: "/template",
  searchUrl: "/search/",
  accountInfo: "/accountinfo",
  staticEndpoint: "/static",
  deleteTemplate: "deletetemplate",
};

export default endpoints;
