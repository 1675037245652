import axios from "axios";

import { getEnvironment, responseErrorInterceptor, getToken, createEMFObject } from "./util";
import { API_END_POINT } from "./constants";
import { parseJwt } from "../authentication/authenticator";

import { Auth } from "aws-amplify";
import Logger from "./logger";

axios.interceptors.request.use(async config => {
  try {
    const token = await getToken();
    const tokenJson = parseJwt(token);
    const currentTime = new Date();
    const twoMinuteLaterTime = new Date(currentTime.getTime() + 2 * 60000);
    if (tokenJson.exp * 1000 > twoMinuteLaterTime) {
      config.headers = config.headers || {};
      //  config.headers.Accept = "application/json";
      config.headers.Authorization = token;
      config.baseURL = `${API_END_POINT[getEnvironment()]}v1/`;
      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/x-amz-json-1.1";
      }
      return config;
    } else {
      localStorage.setItem("redirectURL", window.location.pathname);
      Auth.federatedSignIn({ provider: "Federate" });
    }
  } catch (error) {
    Logger.sessionError(`Authentication error - ${error}`, {
      emfLog: createEMFObject("AuthenticationErrors", 1, "Count"),
    });
    localStorage.setItem("redirectURL", window.location.pathname);
    Auth.federatedSignIn({ provider: "Federate" });
  }
});

axios.interceptors.response.use(response => {
  return response;
}, responseErrorInterceptor);

export default axios;
