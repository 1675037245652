import React, { useState, useEffect } from "react";
import { Text, Button, Icon, BreadcrumbGroup, Breadcrumb, TextButton } from "@amzn/storm-ui";
import { useHistory } from "react-router";
import Row from "../../../components/row";
import { Table } from "../../../components/unified-data-table/unified-data-table";
import {
  FooterPanelContainer,
  PanelGroup,
  Pagination,
  ClientSideExportButton,
  SelectAllCheckbox,
  HeaderPanelContainer,
  BulkActionSelector,
} from "@amzn/unified-data-table-components/pack";
import { download } from "@amzn/storm-ui-icons";

import CustomBulkActionComponent from "../custom-bulk-action-component/CustomBulkActionDialog";
import { overrideZIndex, createEMFObject } from "../../../util/util";
import { ThemeProvider } from "styled-components";
import CreateLabelsForm from "./CreateLabelsForm";
import { useStore } from "../../../store";
import apiClient from "../../../util/apiClient";
import Logger, { logPageMetric } from "../../../util/logger";
import Column from "../../../components/column";
import endpoints from "../../../urlConfig";
import { PAGINATION_CONSTANT } from "../../../util/constants";
import { Page } from "../../../typings/enum";
import ErrorAlert from "../../../components/error-alert/ErrorAlert";

const AdminFeedbackLabels = props => {
  const history = useHistory();
  const { updateStaticData } = useStore();
  const userInfo = useStore(state => state.userInfo);
  const [isError, setIsError] = useState(false);
  const [gridApi, setGridApi] = useState();
  const staticData = useStore(state => state.staticData);
  let editLabelsFeedbackType = "";
  let editLabelsValue = "";
  let editedNotes = "";
  const { staticOperations, staticEndpoint } = endpoints;

  useEffect(() => {
    Logger.sessionInfo("Labels Visited", {
      emfLog: createEMFObject("PageVisits", 1, "Count", {
        PageVisits: "AdminLabelsVisits",
      }),
    });
    logPageMetric(Page.FeedbackLabel);
  }, []);

  const triggerSetEditLabelsObj = editObj => {
    console.log(editObj);
    editLabelsFeedbackType = editObj.selectedFeedbackType;
    editLabelsValue = editObj.labelsValue;
    editedNotes = editObj.notes;
  };

  const getedittedFields = () => {
    return { editLabelsValue, editLabelsFeedbackType, editedNotes };
  };

  const onGridReady = event => {
    const { api } = event;
    setGridApi(api);
    // 1. For client side row model, you need to explicitly decide when to show loading overlay
    api.showLoadingOverlay();

    // 2. Fetch data source
    const labelList = formatLabelforRows(staticData.filter(data => data.docType === "FEEDBACK_LABEL"));
    api.setRowData(labelList);
  };

  const formatLabelforRows = labelList => {
    const formattedLabelList = [];
    labelList.forEach(labelItem => {
      let obj = {
        id: labelItem.id,
      };
      obj["feedbackCategory"] = labelItem?.parent;
      obj["feedbackLabels"] = labelItem?.value;
      formattedLabelList.push(obj);
    });
    return formattedLabelList;
  };

  useEffect(() => {
    const { editLabelsFeedbackType, editLabelsValue } = getedittedFields();
    console.log(editLabelsFeedbackType);
    console.log(editLabelsValue);
    // eslint-disable-next-line
  }, [editLabelsFeedbackType]);

  const columnDefs = [
    {
      colId: "checkboxCol",
      checkboxSelection: true,
      headerComponent: null,
      width: 30,
    },
    {
      colId: "feedbackCategory",
      headerName: "Feedback category",
      field: "feedbackCategory",
      sortable: true,
    },
    {
      colId: "feedbackLabels",
      headerName: "Feedback labels",
      field: "feedbackLabels",
      sortable: true,
    },
  ];

  const bulkActionDefs = [
    {
      bulkActionComponent: CustomBulkActionComponent,
      bulkActionComponentProps: {
        action: "delete",
      },
      bulkActionId: "deleteRowAction",
      label: "Delete",
      getDisabledStatus: selectedRowItem => ({
        disabled: false,
      }),
      getSelectedRowLabel: rowItem => "Delete Label",
      onApply: params => {
        console.log(params.selectedRowItems[0].rowData);
        let feedBackCategoryType = params.selectedRowItems[0]?.rowData?.feedbackCategory;
        let labelInput = params.selectedRowItems[0]?.rowData?.feedbackLabels;
        let id = params.selectedRowItems[0]?.rowData?.id;
        const {
          user: { email },
        } = userInfo;
        let submitObj = {};
        submitObj = {
          entityType: "label",
          operationType: "delete",
          input: {
            id: id,
            fields: {
              docType: "FEEDBACK_LABEL",
              parent: feedBackCategoryType,
              value: labelInput,
              updatedBy: email,
              entityValue: editLabelsValue,
              operationType: "delete",
            },
          },
        };
        apiClient
          .post(staticOperations, submitObj)
          .then(res => onLabelBulkOperationSuccess(feedBackCategoryType, labelInput, "Delete"))
          .catch(err =>
            Logger.sessionError(`Failed to create label: ${labelInput} under category: ${feedBackCategoryType}`, {
              emfLog: createEMFObject(`DeleteFeedbackLabelFailed`, 1, "Count", {
                FeedbackLabel: "DeleteFeedbackLabelFailed",
              }),
            })
          );
      },
    },
    {
      bulkActionComponent: props => (
        <CreateLabelsForm {...props} mode="Edit" triggerSetEditLabelsObj={triggerSetEditLabelsObj} />
      ),
      bulkActionComponentProps: {
        mode: "Edit",
      },
      bulkActionId: "editRowAction",
      label: "Edit",
      getDisabledStatus: selectedRowItem => ({
        disabled: false,
      }),
      getSelectedRowLabel: rowItem => "Edit label",
      onApply: params => {
        let feedBackCategoryType = params.selectedRowItems[0]?.rowData?.feedbackCategory;
        let labelInput = params.selectedRowItems[0]?.rowData?.feedbackLabels;
        let id = params.selectedRowItems[0]?.rowData?.id;
        const {
          user: { email },
        } = userInfo;
        let submitObj = {};
        submitObj = {
          entityType: "label",
          operationType: "update",
          input: {
            id: id,
            fields: {
              docType: "FEEDBACK_LABEL",
              parent: editLabelsFeedbackType,
              value: editLabelsValue,
              updatedBy: email,
              notes: editedNotes,
              entityValue: editLabelsValue,
              operationType: "update",
            },
          },
        };
        apiClient
          .post(staticOperations, submitObj)
          .then(res => onLabelBulkOperationSuccess(feedBackCategoryType, labelInput, "Edit"))
          .catch(err => {
            Logger.sessionError(
              `Failed to create label: ${labelInput} under category: ${feedBackCategoryType} error: ${err}`,
              {
                emfLog: createEMFObject(`UpdateFeedbackLabelFailed`, 1, "Count", {
                  FeedbackLabel: "UpdateFeedbackLabelFailed",
                }),
              }
            );
            setIsError(true);
          });
      },
    },
  ];

  const onLabelBulkOperationSuccess = (feedBackCategoryType, labelInput, operation) => {
    Logger.sessionInfo(`${operation} label successfull: ${labelInput} under category: ${feedBackCategoryType}`, {
      emfLog: createEMFObject(`${operation}FeedbackLabelsCounts`, 1, "Count"),
    });
    const params = {
      size: 10000,
    };
    apiClient
      .get(staticEndpoint, { params })
      .then(response => {
        updateStaticData(
          response?.data?.results?.map(stat => {
            return Object.assign(stat.fields, { id: stat.id });
          })
        );
        history.push("/admin");
      })
      .catch(error => {
        Logger.sessionError(`fetching staticdata failed ${error}`, {
          browserUrl: window.location.href,
        });
        setIsError(true);
      });
  };

  const handleExport = () => {
    Logger.sessionInfo("Exported Labels table", {
      emfLog: createEMFObject("ExportClick", 1, "Count", {
        ExportAction: "LabelsExported",
      }),
    });
    gridApi.exportDataAsCsv({
      allColumns: true,
      onlySelected: false,
      columnKeys: ["feedbackCategory", "feedbackLabels"],
      fileName: "exportlabels.csv",
    });
  };

  const footerElement = (
    <FooterPanelContainer>
      <PanelGroup align="start">
        <ClientSideExportButton
          isDisabled={(rows, pageSize) => (rows === 0 ? true : false)}
          onExport={handleExport}
          label={<Icon type={download} />}
        ></ClientSideExportButton>
      </PanelGroup>
      <PanelGroup align="end">
        <Pagination pageSizes={PAGINATION_CONSTANT} />
      </PanelGroup>
    </FooterPanelContainer>
  );
  const headerPanelElement = (
    <ThemeProvider theme={overrideZIndex}>
      <HeaderPanelContainer>
        <PanelGroup align="start">
          <BulkActionSelector
            labels={{
              selectorText: "Action",
              // dialogTitle: "Row Action",
              // getRowNameDisplayText: ()=>"",
              saveChanges: "Proceed",
              getRowsSelectedDisplayText: count => `${count} Row Selected`,
            }}
            bulkActionDefs={bulkActionDefs}
          />
        </PanelGroup>
      </HeaderPanelContainer>
    </ThemeProvider>
  );
  if (isError) {
    return <ErrorAlert componentName="AdminFeedbackLabels" />;
  }

  return (
    <Column spacingInset="400">
      <BreadcrumbGroup>
        <Breadcrumb>
          <TextButton onClick={() => history.push("/admin")}>Admin</TextButton>
        </Breadcrumb>
        <Breadcrumb label="labels" />
      </BreadcrumbGroup>
      <Text type="h2">Feedback labels</Text>

      <Row>
        <Text type="span">
          This functionality allows admins to create & edit feedback labels. To download the list of all feedback labels
          below, click ‘export’ on the bottom left.
        </Text>
        <Button primary={true} onClick={() => history.push("/admin/labels/create")}>
          Create feedback label
        </Button>
      </Row>
      <Table
        onGridReady={onGridReady}
        gridOptions={{
          columnDefs,
          pagination: true,
          getRowId: rowData => {
            console.log(rowData);
            return rowData.data.id;
          },
          frameworkComponents: {
            SelectAllCheckbox,
          },
          rowSelection: "single",
          isRowSelectable: rowNode => true,
        }}
        tableFooter={footerElement}
        headerPanel={headerPanelElement}
      />
    </Column>
  );
};

export default AdminFeedbackLabels;
