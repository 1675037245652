import { COUNTERS, OPS_METRICS_TYPES } from "../constants";
import { MetricsConfig } from "../types";
import { whenAmazonTakt } from "@amzn/takt-utils";
import { clean } from "./cloudwatch-rum-provider";

type Params = Pick<MetricsConfig, "siteName" | "serviceName">;

/**
 * A MetricsProvider class that publishes metrics to Takt: https://w.amazon.com/bin/view/SSPA/AX/ACPT/Telemetry/Takt/
 * An instance of the takt client exists on the window object under window.AmazonTakt.
 * We use whenAmazonTakt to wait until it's ready before calling it.
 */
export class TaktProvider {
  params: Params;
  constructor(params: Params) {
    this.params = params;
  }

  publishCounter = (
    operation: string,
    metricName: string,
    count: number,
    properties?: Record<string, string>
  ) => {
    this.recordEvent(operation, metricName, count, properties);
  };

  publishTimer = (
    operation: string,
    metricName: string,
    time: number,
    properties?: Record<string, string>
  ) => {
    this.recordEvent(operation, metricName, time, properties);
  };

  publishPageView = (
    pageId: string,
    pageAttributes?: Record<string, string>
  ) => {
    this.publishCounter(COUNTERS.PageVisit, OPS_METRICS_TYPES.Count, 1, {
      pageId,
      ...pageAttributes,
    });
  };

  private recordEvent = (
    operation: string,
    metricName: string,
    value: number,
    properties?: Record<string, string>
  ) => {
    const { siteName, serviceName } = this.params;
    const eventName = `com.amazon.ads.${clean(siteName)}.${clean(
      serviceName
    )}.${clean(operation)}.${clean(metricName)}`;
    whenAmazonTakt((amazonTakt) => {
      if (!amazonTakt?.logCustom) {
        console.log(
          `[TelemetryTaktProviderException] Failed to call logCustom on ${operation} failed: logCustom is empty.`
        );
        return;
      }
      try {
        amazonTakt.logCustom({
          context: eventName,
          details: JSON.stringify({
            value,
            ...properties,
          }),
        });
      } catch (error) {
        console.warn(
          `[AmazonTaktException] Failed to log ${operation}.`,
          error
        );
      }
    });
  };
}
