import React, { useState, useEffect } from "react";
import { Text, Button, Icon, BreadcrumbGroup, Breadcrumb, TextButton } from "@amzn/storm-ui";
import { useHistory } from "react-router";
import Row from "../../../components/row";
import { Table } from "../../../components/unified-data-table/unified-data-table";
import {
  FooterPanelContainer,
  PanelGroup,
  Pagination,
  ClientSideExportButton,
  SelectAllCheckbox,
  LinkCellRenderer,
} from "@amzn/unified-data-table-components/pack";
import { download } from "@amzn/storm-ui-icons";

import { useStore } from "../../../store";
import Column from "../../../components/column";
import { PAGINATION_CONSTANT } from "../../../util/constants";
import { createEMFObject } from "../../../util/util";
import Logger, { logPageMetric } from "../../../util/logger";
import { Page } from "../../../typings/enum";

const AdminFeedbackcategorization = props => {
  const history = useHistory();
  const [gridApi, setGridApi] = useState();
  const { entity } = props;
  const staticData = useStore(state => state.staticData);
  useEffect(() => {
    Logger.sessionInfo("Categorization Visited", {
      emfLog: createEMFObject("PageVisits", 1, "Count", {
        PageVisits: "AdminCategorizationVisits",
      }),
    });
    logPageMetric(Page.FeedbackCategorization);
  }, []);
  const onGridReady = event => {
    const { api } = event;
    setGridApi(api);
    // 1. For client side row model, you need to explicitly decide when to show loading overlay
    api.showLoadingOverlay();
    const categoryMap = staticData
      .filter(data => data.docType === "FEEDBACK_CATEGORY")
      .reduce((map, obj) => {
        map[obj.value] = obj.parent;
        return map;
      }, {});
    const servicesList = formatCategorizationforRows(
      staticData.filter(data => data.docType === "PRODUCT_SERVICES"),
      categoryMap
    );
    api.setRowData(servicesList);
  };

  const formatCategorizationforRows = (servicesList, categoryMap) => {
    const formattedservicesList = [];
    servicesList.forEach(serviceItem => {
      let obj = {
        id: serviceItem.id,
      };
      obj["feedbackCategory"] = serviceItem?.parent;
      obj["productService"] = serviceItem?.value;
      obj["feedbackType"] = categoryMap[serviceItem?.parent];
      formattedservicesList.push(obj);
    });
    return formattedservicesList;
  };

  const columnDefs = [
    {
      colId: "feedbackType",
      headerName: "Feedback type",
      field: "feedbackType",
      sortable: true,
    },
    {
      colId: "feedbackCategory",
      headerName: "Feedback category",
      field: "feedbackCategory",
      sortable: true,
    },
    {
      colId: "productService",
      headerName: "Product/Service",
      field: "productService",
      sortable: true,
    },
  ];

  const handleExport = () => {
    Logger.sessionInfo("Exported Categorization table", {
      emfLog: createEMFObject("ExportClick", 1, "Count", {
        ExportAction: "CategorizationExported",
      }),
    });
    gridApi.exportDataAsCsv({
      allColumns: true,
      onlySelected: false,
      columnKeys: ["feedbackType", "feedbackCategory", "productService"],
      fileName: "exportcategorization.csv",
    });
  };

  const footerElement = (
    <FooterPanelContainer>
      <PanelGroup align="start">
        <ClientSideExportButton
          isDisabled={(rows, pageSize) => (rows === 0 ? true : false)}
          onExport={handleExport}
          label={<Icon type={download} />}
        ></ClientSideExportButton>
      </PanelGroup>
      <PanelGroup align="end">
        <Pagination pageSizes={PAGINATION_CONSTANT} />
      </PanelGroup>
    </FooterPanelContainer>
  );

  return (
    <Column spacingInset="400">
      <BreadcrumbGroup>
        <Breadcrumb>
          <TextButton onClick={() => history.push("/admin")}>Admin</TextButton>
        </Breadcrumb>
        <Breadcrumb label="categorization" />
      </BreadcrumbGroup>
      <Text type="h2">Feedback categorization</Text>
      <Row>
        <Text type="span">
          This functionality allows admins to create & edit feedback categories. To download the list of all feedback
          categories below, click ‘export’ on the bottom left.
        </Text>
        <Button primary={true} onClick={() => history.push("/admin/categorization/create")}>
          Create feedback {entity}
        </Button>
        <Button primary={false} onClick={() => history.push("/admin/categorization/update")}>
          Edit feedback {entity}
        </Button>
        <Button primary={false} onClick={() => history.push("/admin/categorization/delete")}>
          Delete feedback {entity}
        </Button>
      </Row>
      <Table
        onGridReady={onGridReady}
        frameworkComponents={SelectAllCheckbox}
        gridOptions={{
          columnDefs,
          components: {
            LinkCellRenderer,
          },
          pagination: true,
          getRowId: rowData => {
            console.log(rowData);
            return rowData.data.id;
          },
          frameworkComponents: {
            SelectAllCheckbox,
          },
          rowSelection: "single",
          isRowSelectable: rowNode => true,
        }}
        tableFooter={footerElement}
      />
    </Column>
  );
};

export default AdminFeedbackcategorization;
