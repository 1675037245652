import * as React from "react";
import axios from "axios";
import { EMPTY_DATA, revenueBuckets } from "./constants";
import { parseJwt } from "../authentication/authenticator";
import { getDates } from "./tableFiltering";
import Logger from "./logger";
import { Text } from "@amzn/storm-ui";
import { Amplify, Auth } from "aws-amplify";
import AuthUserPoolConfig from "./config";
import download from "downloadjs";

export const formatCurrency = amount => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: "symbol",
    style: "currency",
    currency: "USD",
  });

  if (amount && !isNaN(amount)) {
    return <Text>{currencyFormatter.format(amount)}</Text>;
  } else {
    return EMPTY_DATA;
  }
};

export const formatNumber = number => {
  if (number && !isNaN(number) && number !== "0.0") {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  } else {
    return EMPTY_DATA;
  }
};

export const isVectorWidget = window => {
  return (
    window &&
    window.localStorage &&
    window.localStorage.getItem("pathName") &&
    window.localStorage.getItem("pathName").includes("acfwidget")
  );
};

export const formatDate = epochDate => {
  if (epochDate && epochDate !== "0") {
    const date = new Date(0);
    date.setUTCSeconds(epochDate);

    if (date instanceof Date && !isNaN(date)) {
      return <Text>{`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`}</Text>;
    } else {
      return EMPTY_DATA;
    }
  } else {
    return EMPTY_DATA;
  }
};

export const spacingTokens = {
  none: "0px",
  100: "2px",
  200: "4px",
  300: "8px",
  400: "16px",
  450: "24px",
  500: "32px",
  600: "64px",

  // TODO (5.x): Remove in favor of new spacing tokens
  xxsmall: "2px",
  xsmall: "4px",
  small: "8px",
  medium: "16px",
  large: "32px",
  xlarge: "64px",
};

// Parses the value for a spacing prop. This turns a string of spacing names
// like "medium" or "small medium" into an array of spacing values like ["16px"]
// or ["8px", "16px"].
export const parseSpacing = spacing => (spacing ? spacing.split(" ").map(spacing => spacingTokens[spacing] || 0) : []);

export const filterPropsByPrefix = (props, prefixes) =>
  Object.keys(props)
    .filter(name => prefixes.some(prefix => name.indexOf(prefix) === 0))
    .reduce((result, name) => {
      result[name] = props[name];
      return result;
    }, {});

const horizontalDirectionMap = {
  start: "left",
  end: "right",
};

export const getHorizontalDirection = str =>
  Object.prototype.hasOwnProperty.call(horizontalDirectionMap, str) ? horizontalDirectionMap[str] : str;

export const formatDateTime = epochDate => {
  if (epochDate && epochDate !== "0") {
    const date = new Date(0);
    date.setUTCSeconds(epochDate);

    if (date instanceof Date && !isNaN(date)) {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return month + "/" + day + "/" + year;
    } else {
      return EMPTY_DATA;
    }
  } else {
    return EMPTY_DATA;
  }
};

export const getEnvironment = () => {
  const currentHost = window.location.host;
  let envHash = {
    "localhost:3000": "dev",
    "beta.unity.advertising.amazon.dev": "beta",
    "beta.acfportal.advertising.amazon.dev": "beta",
    "gamma.unity.advertising.amazon.dev": "gamma",
    "gamma.acfportal.advertising.amazon.dev": "gamma",
    "unity.advertising.amazon.dev": "prod",
    "acfportal.advertising.amazon.dev": "prod",
  };
  return envHash[currentHost];
};

export const truncateText = (text, length = 60) => {
  if (text && text.length > length) {
    return <Text>{text.substring(0, length)}...</Text>;
  } else {
    return text ? <Text>{text}</Text> : EMPTY_DATA;
  }
};

export const getUserAlias = () => {
  const token = localStorage.getItem("token");
  const user = parseJwt(token);
  let userId;

  if (user && Array.isArray(user.identities) && user.identities.length > 0) {
    userId = user.identities[0].userId;
  }
  return userId;
};

export const formatString = (data, truncate = true, limit = 60) => {
  if (data && data === "NULL") {
    return <Text>{EMPTY_DATA}</Text>;
  } else if (data && truncate) {
    return truncateText(data, limit);
  } else if (data) {
    return <Text>{data}</Text>;
  } else {
    return <Text>{EMPTY_DATA}</Text>;
  }
};

export const cleanUpDescription = description => {
  if (!description) {
    return description;
  }
  let cutOffString = "**All Related Anecdotes**";
  description = description.replace("###Description###", "");
  if (description.includes(cutOffString)) {
    description = description.substr(0, description.indexOf(cutOffString));
    if (description.includes("-")) {
      description = description.replace("-", "");
    }
  }
  description = description.trim();
  if (description[description.length - 1] === "-") {
    description = description.slice(0, -1);
  }
  return description;
};

export const responseErrorInterceptor = error => {
  Logger.sessionError(`API failure ${error}`, {
    baseURL: error?.config?.baseURL,
    params: error?.config?.params,
    method: error?.config?.method,
    url: error?.config?.url,
    browserUrl: window.location.href,
  });

  return new Promise((resolve, reject) => {
    if (error.response && error.response.status === 401) {
      Logger.sessionError(`response with 401 unauthoried error recieved ${error}`, {
        emfLog: createEMFObject("UnauthorizedUserResponse", 1, "Count"),
      });
      return resolve(getAuthenticated(error));
    } else {
      reject(error);
    }
  });
};

export const getAuthenticated = (error = new Error()) => {
  Amplify.configure({
    Auth: AuthUserPoolConfig[getEnvironment()],
  });
  localStorage.setItem("redirectURL", window.location.pathname);
  // eslint-disable-next-line
  // @ts-ignore
  Auth.currentAuthenticatedUser()
    .then(cognitoUser => {
      error.config.headers.Authorization = cognitoUser.signInUserSession.idToken.jwtToken;
      // localStorage.setItem("token", cognitoUser.signInUserSession.idToken.jwtToken);
      return axios(error.config);
    })
    .catch(error => {
      localStorage.setItem("redirectURL", window.location.pathname);
      Auth.federatedSignIn({ provider: "Federate" });
    });
};

export const validateToken = token => {
  const user = parseJwt(token);
  const ONE_HOUR = 60 * 60;
  if (new Date(user.exp) - Math.floor(Date.now() / 1000) > ONE_HOUR) {
    return true;
  }
  return false;
};

export const getQueryParams = query => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
    let [key, value] = param.split("=");
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
    return params;
  }, {});
};
export const jsonParseData = data => {
  let result;
  if (data) {
    try {
      result = JSON.parse(data);
      if (result["createdTimestamp"]) {
        let timeData = result["createdTimestamp"];
        if (timeData.period && timeData.period === "LIFETIME") {
          delete result["createdTimestamp"];
        }
      }
    } catch (e) {
      Logger.sessionError(`Not able to parse JSON String ${e}`);
    }
  }
  return result;
};
export const processFeedbacks = feedbacks =>
  feedbacks.map(feedback => {
    let bucket;

    if (feedback?.fields?.revenueImpacted !== "0.0") {
      bucket = revenueBuckets.find(bucket => bucket.condition(parseFloat(feedback?.fields?.revenueImpacted)));
      if (bucket) {
        bucket = bucket.name;
      }
    }

    return Object.assign(feedback?.fields, {
      id: feedback?.id,
      dates: getDates(feedback?.fields?.createdTimestamp),
      revenueImpacted: feedback?.fields?.revenueImpacted ? parseFloat(feedback?.fields?.revenueImpacted) : 0.0,
      opportunityValue: feedback?.fields?.opportunityValue ? parseFloat(feedback?.fields?.opportunityValue) : 0.0,
      accountName: removeMultipleSpacesWithSingle(feedback?.fields?.accountName),
      blockedRevenueBucket: bucket,
    });
  });

export const removeMultipleSpacesWithSingle = text => {
  return text && text.replace(/\s\s+/g, " ").trim();
};

export const processReviewMappings = cases =>
  cases.map(approvalCase => {
    return Object.assign(approvalCase?.fields, {
      id: approvalCase?.id,
      dates: getDates(approvalCase?.fields?.createdTimestamp),
    });
  });

export const processVotes = votes =>
  votes.map(vote => {
    return Object.assign(vote.fields, {
      id: vote.id,
    });
  });

export const throttle = (callback, wait, immediate = false) => {
  let timeout = null;
  let initialCall = true;

  return function () {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, arguments);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, wait);
    }
  };
};

export const sortResults = (results, sortColumn, sortDirection) => {
  results?.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1;
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1;
    }
    return 0;
  });
  return results;
};

/**
 * Filters an array of objects using custom predicates.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export function filterArray(array, filters) {
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== "function") return true;
      return filters[key](item[key]);
    });
  });
}

export const getToken = () => {
  Amplify.configure({
    Auth: AuthUserPoolConfig[getEnvironment()],
  });
  return Auth.currentAuthenticatedUser()
    .then(cognitoUser => {
      return cognitoUser.signInUserSession.idToken.jwtToken;
    })
    .catch(error => {
      Logger.sessionError(`Error while fetching token - ${error}`);
      Auth.federatedSignIn({ provider: "Federate" });
    });
};

export const verifyThemeRights = async () => {
  let token = await getToken();
  const user = parseJwt(token);
  const ldapgrp = JSON.parse(user["custom:ldapgrp"]);
  if (ldapgrp && ldapgrp.includes("theme-admin")) {
    return true;
  }
  return false;
};

export const verifyReviewerRights = async () => {
  let token = await getToken();
  const user = parseJwt(token);
  const ldapgrp = JSON.parse(user["custom:ldapgrp"]);
  if (ldapgrp && ldapgrp.includes("ad-sales-tech-product-clf-reviewers")) {
    return true;
  }
  return false;
};

export const createEMFObject = (metricName, metricValue, metricUnit, dimension = {}, nameSpace = "ACFPortalUI") => {
  return {
    _aws: {
      Timestamp: Date.now(),
      CloudWatchMetrics: [
        {
          Namespace: nameSpace,
          Dimensions: Object.keys(dimension).map(d => [d]),
          Metrics: [
            {
              Name: metricName,
              Unit: metricUnit,
            },
          ],
        },
      ],
    },
    ...dimension,
    [metricName]: metricValue,
  };
};

export const overrideZIndex = theme => ({
  ...theme,
  popover: {
    ...theme.popover,
    zIndex: 700,
  },
});

export const downloadInChunk = (response, fileName) => {
  Logger.sessionInfo(`file downloaded ${fileName}`, {
    emfLog: createEMFObject("AttachmentDownload", 1, "Count"),
  });
  let responseContentBuffer = new Int8Array();
  const reader = response.body.getReader();
  function handleChunk({ done, value }) {
    if (done) {
      download(responseContentBuffer, fileName);
      return;
    }
    responseContentBuffer = Int8Array.from([...responseContentBuffer, ...value]);

    reader.read().then(handleChunk);
  }

  //retreive first value
  reader.read().then(handleChunk);
};

export const getStaticData = (type, parent = "", staticData) => {
  let dataArr = [];
  let entities;
  if (staticData.length) {
    if (parent) {
      entities = staticData.filter(obj => {
        return obj.docType === type && obj.parent === parent;
      });
    } else {
      entities = staticData.filter(obj => {
        return obj.docType === type;
      });
    }

    for (let data of entities) {
      dataArr.push(data.value);
    }
  }
  return dataArr;
};

export const stringComparatorForArrayOfObjects = (a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getEnvUrl = (objectId, actionType) => {
  if (inIframe()) {
    if (getEnvironment() === "prod") {
      return `https://ams-amazon.my.salesforce.com/lightning/cmp/c__CustomerFeedbackAura?c__objectId=${objectId}&c__actionType=${actionType}`;
    } else {
      return `https://ams-amazon--beta.sandbox.lightning.force.com/lightning/cmp/c__CustomerFeedbackAura?c__objectId=${objectId}&c__actionType=${actionType}`;
    }
  } else {
    return `${window.location.hostname}/${actionType}/${objectId}`;
  }
};

export const toUnix = date => {
  if (date) {
    return Math.floor(new Date(date).getTime() / 1000);
  }
};

export const toMiliSeconds = timeStamp => {
  if (timeStamp) {
    return new Date(parseInt(timeStamp, 10) * 1000);
  }
};

export const isOlderdate = timeStamp => {
  const currentDate = new Date();
  const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  if (new Date(timeStamp) < today) {
    return true;
  } else {
    return false;
  }
};

export const dateRangeFilterComparator = (cellDateValue, model) => {
  if (model.period === "LIFETIME") {
    return true;
  }
  const cellDate = new Date(cellDateValue).getTime();
  const startDate = new Date(model.dateFrom).getTime();
  const endDate = new Date(model.dateTo).getTime();

  return (cellDateValue === null && model.period === "LIFETIME") || (cellDate >= startDate && cellDate <= endDate);
};

export const getLastUpdateTimestamp = feedbacks => {
  let latestTime = parseInt(getTwoyearOldTimeStamp());
  for (const fb of feedbacks) {
    if (fb.lastUpdateTimestamp) {
      latestTime = Math.max(latestTime, parseInt(fb.lastUpdateTimestamp));
    }
  }
  return latestTime;
};

export const getTwoyearOldTimeStamp = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  date.setHours(0, 0, 0, 0);
  const timeStamp = Math.floor(date.getTime() / 1000);
  return timeStamp.toString();
};

export const removeRecievedEntriesFromStoreFeedback = (freshFeedbacks, oldFeedbacks) => {
  // remove feedbacks older than 2 year window from oldFeedbacks
  const twoYearOldTimeStamp = getTwoyearOldTimeStamp();
  oldFeedbacks = oldFeedbacks.filter(fb => Number(fb.lastUpdateTimestamp) >= twoYearOldTimeStamp);
  const fbSet = new Set();
  for (const fb of freshFeedbacks) {
    fbSet.add(fb.id);
  }
  const deDupedFb = oldFeedbacks.filter(fb => !fbSet.has(fb.id));
  return deDupedFb;
};

export const dedupResponse = response => {
  const responseMap = new Map();
  response.forEach(item => {
    responseMap.set(item.id, item);
  });
  return Array.from(responseMap.values());
};
