import React from "react";

// Lodash
import _ from "lodash";

import { UnifiedDataTable } from "@amzn/unified-data-table-components/pack";

import UnifiedDataTableLicense from "@amzn/unified-data-table-license";
import { UdcLicenseManager } from "@amzn/unified-data-table-components/pack";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const keyString = UnifiedDataTableLicense.getLicenseKey();
UdcLicenseManager.setLicenseKey(keyString);

export class Table extends React.Component {
  static getDefaultGridOptions() {
    return {
      suppressMenu: true,
      defaultColDef: {
        headerClass: "tableHeader",
        cellClass: "tableCell",
      },
    };
  }

  render() {
    const {
      uidPrefix,
      tableFooter,
      gridOptions,
      onGridReady,
      headerPanel,
      onFilterChanged,
      onColumnPresetChanged,
      frameworkComponents,
    } = this.props;

    return (
      <UnifiedDataTable
        frameworkComponents={frameworkComponents || {}}
        gridOptions={_.merge(Table.getDefaultGridOptions(), gridOptions)}
        uidPrefix={uidPrefix}
        onGridReady={onGridReady}
        footerPanel={tableFooter}
        headerPanel={headerPanel}
        onFilterChanged={onFilterChanged}
        onColumnPresetChanged={onColumnPresetChanged}
        maxTableHeight="67vh"
        locale="en-US"
      />
    );
  }
}
