import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Divider, Button, TextButton, TextAreaFormGroup } from "@amzn/storm-ui";
import { Dropdown, DropdownItem } from "@amzn/storm-ui";
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { useStore } from "../../../store";
import apiClient from "../../../util/apiClient";
import Logger from "../../../util/logger";
import Row from "../../../components/row";
import { getStaticData, createEMFObject, stringComparatorForArrayOfObjects } from "../../../util/util";
import endpoints from "../../../urlConfig";
import ErrorAlert from "../../../components/error-alert/ErrorAlert";

const CreateFeedbackProductServices = props => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [notes, setNotes] = useState("");
  const [isError, setIsError] = useState(false);
  const [feedbackCategories, setFeedbackCategories] = useState([]);
  const [productServiceInput, setProductServiceInput] = useState("");
  const [productAndServices, setProductAndServices] = useState([]);
  const [selectedProductAndService, setSelectedProductAndService] = useState({});
  const history = useHistory();
  const { mode } = props;
  const { updateStaticData } = useStore();
  const staticData = useStore(state => state.staticData);

  const userInfo = useStore(state => state.userInfo);
  const { staticOperations, staticEndpoint } = endpoints;

  useEffect(() => {
    setFeedbackCategories(getStaticData("FEEDBACK_CATEGORY", "", staticData).sort());
    const servicesList = staticData.filter(
      data => data.docType === "PRODUCT_SERVICES" && data.value !== "All Products"
    );
    servicesList.sort(stringComparatorForArrayOfObjects);
    setProductAndServices(servicesList);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedCategory(selectedProductAndService.parent);
    setProductServiceInput(selectedProductAndService.value);
  }, [selectedProductAndService]);

  const closeCreateModal = () => {
    history.push("/admin/categorization");
  };

  const validateSubmit = () => {
    return !selectedCategory || !productServiceInput || !notes;
  };

  const validateEdit = () => {
    const isdisabled =
      !productServiceInput ||
      !notes ||
      !selectedCategory ||
      (selectedProductAndService.value === productServiceInput &&
        selectedProductAndService.parent === selectedCategory);
    return isdisabled;
  };

  const createProductServiceSuccess = res => {
    Logger.sessionInfo(`${mode} Feedback Product/Service Successfull: ${productServiceInput}`, {
      emfLog: createEMFObject(`${mode}FeedbackProductServiceCounts`, 1, "Count"),
    });
    const params = {
      size: 10000,
    };
    apiClient
      .get(staticEndpoint, { params })
      .then(response => {
        updateStaticData(
          response?.data?.results?.map(stat => {
            return Object.assign(stat.fields, { id: stat.id });
          })
        );
        history.push("/admin/categorization");
      })
      .catch(error => {
        Logger.sessionError(`fetching staticdata failed ${error}`, {
          browserUrl: window.location.href,
        });
        setIsError(true);
      });
  };

  const handleEdit = () => {
    let submitObj = {};
    const {
      user: { email },
    } = userInfo;
    submitObj = {
      entityType: "productServices",
      operationType: "update",
      input: {
        id: selectedProductAndService.id,
        fields: {
          docType: "PRODUCT_SERVICES",
          parent: selectedCategory,
          value: productServiceInput,
          updatedBy: email,
          notes: notes,
          entityValue: productServiceInput,
          operationType: "update",
        },
      },
    };
    apiClient
      .post(staticOperations, submitObj)
      .then(createProductServiceSuccess)
      .catch(err => {
        Logger.sessionError(`Failed to update Product Services: ${productServiceInput} err:${err}`, {
          emfLog: createEMFObject(`UpdateProductServicesFailed`, 1, "Count", {
            ProductServices: "UpdateProductServicesFailed",
          }),
        });
        setIsError(true);
      });
  };

  const handleSubmit = () => {
    let submitObj = {};
    const {
      user: { email },
    } = userInfo;
    submitObj = {
      entityType: "productServices",
      operationType: "create",
      input: {
        fields: {
          docType: "PRODUCT_SERVICES",
          parent: selectedCategory,
          value: productServiceInput,
          updatedBy: email,
          notes: notes,
          entityValue: productServiceInput,
          operationType: "create",
        },
      },
    };
    apiClient
      .post(staticOperations, submitObj)
      .then(createProductServiceSuccess)
      .catch(err => {
        Logger.sessionError(`Failed to create Feedback Category: ${productServiceInput} err: ${err}`, {
          emfLog: createEMFObject(`CreateProductServicesFailed`, 1, "Count", {
            ProductServices: "CreateProductServicesFailed",
          }),
        });
        setIsError(true);
      });
  };
  if (isError) {
    return <ErrorAlert componentName="CreateProductService" />;
  }
  return (
    <Form id="createLabel">
      {mode !== "Create" && (
        <FormRow
          label="Select Product/Service"
          isSectionHeader
          id="feedback-services-dropdown-row"
          controlComponents={[
            componentId => (
              <Dropdown
                onChange={val => setSelectedProductAndService(val)}
                selectedValue={selectedProductAndService}
                onOverrideLabel={(dropdownItem, value) => {
                  if (value.id) {
                    return dropdownItem;
                  }
                  return "Choose Product/Service to update";
                }}
                error={!selectedProductAndService?.value}
              >
                {productAndServices.map((type, key) => {
                  return (
                    <DropdownItem key={key} value={type}>
                      {type.value}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            ),
          ]}
        />
      )}
      <FormRow
        label="Feedback category"
        isSectionHeader
        id="feedback-category-row"
        controlComponents={[
          componentId => (
            <Dropdown
              onChange={val => setSelectedCategory(val)}
              selectedValue={selectedCategory}
              onOverrideLabel={(dropdownItem, value) => {
                if (value) {
                  return dropdownItem;
                }
                return "Choose parent feedback category";
              }}
              error={!selectedCategory}
            >
              {feedbackCategories.map((category, key) => (
                <DropdownItem key={key} value={category}>
                  {category}
                </DropdownItem>
              ))}
            </Dropdown>
          ),
        ]}
      />
      <Divider />
      <FormRow
        label="Product/Service"
        isSectionHeader
        id="feedback-category-row"
        controlComponents={[
          componentId => (
            <TextAreaFormGroup
              placeholder="Create product/service"
              value={productServiceInput}
              onChange={event => {
                setProductServiceInput(event.target.value);
              }}
              statusType={selectedCategory && !productServiceInput ? "error" : undefined}
            />
          ),
        ]}
      />
      <Divider />
      <FormRow
        label="Remarks"
        isSectionHeader
        id="remarks-row"
        controlComponents={[
          componentId => (
            <TextAreaFormGroup
              placeholder="Remarks"
              value={notes}
              onChange={event => {
                setNotes(event.target.value);
              }}
              statusType={productServiceInput && !notes ? "error" : undefined}
            />
          ),
        ]}
      />
      <Divider />
      <Row alignmentHorizontal="right">
        <TextButton onClick={closeCreateModal}>Cancel</TextButton>
        <Button
          disabled={mode === "Create" ? validateSubmit() : validateEdit()}
          primary={true}
          onClick={() => (mode === "Create" ? handleSubmit() : handleEdit())}
        >
          {`${mode} Feedback Product/Service`}
        </Button>
      </Row>
    </Form>
  );
};

export default CreateFeedbackProductServices;
