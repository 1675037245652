import { MetricsConfig, MetricsProviderInterface } from "../types";

const TAG = "TelemetryConsoleLogProvider";

export class ConsoleLogProvider {
  params?: MetricsConfig;
  constructor(params?: MetricsConfig) {
    this.params = params;
  }
  publishCounter = (
    ...args: Parameters<MetricsProviderInterface["publishCounter"]>
  ) => {
    console.log(
      `[${TAG}] publishCounter called with arguments`,
      args,
      "Shared context: ",
      this.params?.sharedContext
    );
  };
  publishTimer = (
    ...args: Parameters<MetricsProviderInterface["publishTimer"]>
  ) => {
    console.log(
      `[${TAG}] publishTimer called with arguments`,
      args,
      "Shared context: ",
      this.params?.sharedContext
    );
  };
  publishPageView = (
    ...args: Parameters<MetricsProviderInterface["publishPageView"]>
  ) => {
    console.log(
      `[${TAG}] publishPageView called with arguments`,
      args,
      "Shared context: ",
      this.params?.sharedContext
    );
  };
}
