import React from "react";
import { Alert } from "@amzn/storm-ui";
import Logger from "../../util/logger";
import { createEMFObject } from "../../util/util";

class ErrorAlert extends React.Component {
  componentDidMount() {
    const { componentName = "Unknown" } = this.props;
    Logger.sessionError("Showing Something went wrong to user", {
      emfLog: createEMFObject("SomethingWentWrongPageView", 1, "Count"),
    });
    // adding separate log because message is not shown when publishing metrics
    Logger.sessionError("Showing Something went wrong to user", {
      browserUrl: window.location.href,
      componentName: componentName,
    });
  }

  render() {
    return (
      <Alert type="error">
        Something went wrong. Please try again. <a href=".">Reload</a>{" "}
      </Alert>
    );
  }
}

export default ErrorAlert;
