import get from "lodash/get";
import { OPS_METRICS_TYPES } from "../constants";
import { TelemetryClientInterface } from "../types";
import isFunction from "lodash/isFunction";

export type AnyFunction = (...args: any[]) => any;
export type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;

interface ErrorParser {
  getErrorMessage: (error: unknown) => string;
  getStatusCode: (error: unknown) => number;
  getRequestId: (error: unknown) => string;
  getUrl: (error: unknown) => string;
}

export const DefaultErrorParser = {
  getErrorMessage: (error: unknown) => {
    return get(error, "errorMessage", "") || get(error, "message", "");
  },
  getStatusCode: (error: unknown) => {
    return get(error, "statusCode") || get(error, "status", -1);
  },
  getRequestId: (error: unknown) => {
    return get(error, "requestId", "");
  },
  getUrl: (error: unknown) => {
    return get(error, "url", "");
  },
};

type TelemetryClientGetter =
  | TelemetryClientInterface
  | (() => TelemetryClientInterface);

const getTelemetryClient = (telemetryClientGetter: TelemetryClientGetter) => {
  if (isFunction(telemetryClientGetter)) {
    return telemetryClientGetter();
  } else {
    return telemetryClientGetter;
  }
};

const handleError = ({
  telemetryClient,
  operationName,
  error,
  errorParser,
}: {
  telemetryClient: TelemetryClientInterface;
  operationName: string;
  error: unknown;
  errorParser: ErrorParser;
}) => {
  const TAG = `[Operation ${operationName}] `;
  const logger = telemetryClient.getLogger(TAG);
  logger.error(`Operation ${operationName} failed.`);
  logger.error(error);
  const errorMessage = errorParser.getErrorMessage(error);
  const statusCode = errorParser.getStatusCode(error);
  const requestId = errorParser.getRequestId(error);
  const statusCodeString = statusCode === -1 ? "" : `${statusCode}`;
  if (statusCode >= 400 && statusCode < 500) {
    telemetryClient.publishCounter(operationName, OPS_METRICS_TYPES.Error, 1, {
      errorMessage,
      statusCode: statusCodeString,
      requestId,
    });
  } else {
    telemetryClient.publishCounter(operationName, OPS_METRICS_TYPES.Fault, 1, {
      errorMessage,
      statusCode: statusCodeString,
      requestId,
    });
  }
  telemetryClient.abortTimer(operationName);
};

/**
 * Higher-order function, takes in a function and returns the same function wrapped with logic to emit operational metrics.
 * Input and output functions will have the same signature.
 * The function emits two counters and one timer: (OperationName, Count), (OperationName, Success/Fault), (OperationName, Time)
 */
export const withInstrumentation = <T extends AnyFunction>(
  telemetryClientGetter: TelemetryClientGetter,
  operationName: string,
  operation: T,
  errorParser: ErrorParser = DefaultErrorParser
): ((...args: Parameters<T>) => Promise<UnwrapPromise<ReturnType<T>>>) => {
  const instrumentedInputFunction = async (
    ...args: Parameters<T>
  ): Promise<UnwrapPromise<ReturnType<T>>> => {
    const TAG = `[Operation ${operationName}] `;
    const telemetryClient = getTelemetryClient(telemetryClientGetter);
    const logger = telemetryClient.getLogger(TAG);
    // Start timer to compute latency
    telemetryClient.startTimer(operationName);
    // Emit a count of how many times the operation happened
    telemetryClient.publishCounter(operationName, OPS_METRICS_TYPES.Count, 1);
    logger.info(`Starting operation ${operationName}`);
    try {
      const operationResult = await operation(...args);
      const elapsedTime = telemetryClient.stopTimer(operationName);
      logger.info(`Operation ${operationName} completed in ${elapsedTime}ms`);
      telemetryClient.publishCounter(
        operationName,
        OPS_METRICS_TYPES.Success,
        1
      );
      return operationResult;
    } catch (error) {
      handleError({
        error,
        errorParser,
        telemetryClient,
        operationName,
      });
      throw error;
    }
  };
  return instrumentedInputFunction;
};

export const withInstrumentationSync = <T extends AnyFunction>(
  telemetryClientGetter:
    | TelemetryClientInterface
    | (() => TelemetryClientInterface),
  operationName: string,
  operation: T,
  errorParser: ErrorParser = DefaultErrorParser
): ((...args: Parameters<T>) => ReturnType<T>) => {
  const instrumentedInputFunction = (...args: Parameters<T>): ReturnType<T> => {
    const TAG = `[Operation ${operationName}] `;
    const telemetryClient = getTelemetryClient(telemetryClientGetter);
    const logger = telemetryClient.getLogger(TAG);
    // Start timer to compute latency
    telemetryClient.startTimer(operationName);
    // Emit a count of how many times the operation happened
    telemetryClient.publishCounter(operationName, OPS_METRICS_TYPES.Count, 1);
    logger.info(`Starting operation ${operationName}`);
    try {
      const operationResult = operation(...args);
      const elapsedTime = telemetryClient.stopTimer(operationName);
      logger.info(`Operation ${operationName} completed in ${elapsedTime}ms`);
      telemetryClient.publishCounter(
        operationName,
        OPS_METRICS_TYPES.Success,
        1
      );
      return operationResult;
    } catch (error) {
      handleError({
        error,
        errorParser,
        telemetryClient,
        operationName,
      });
      throw error;
    }
  };
  return instrumentedInputFunction;
};
