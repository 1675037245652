import { getEnvironment } from "./util";

export function getUserAlias(userEmail) {
  return userEmail.substring(0, userEmail.indexOf("@"));
}

export function getPhoneToolLink(userEmail) {
  if (userEmail) {
    const userAlias = userEmail.substring(0, userEmail.indexOf("@"));
    return "https://phonetool.amazon.com/users/" + userAlias;
  }
  return "";
}

export function getUserPhotoLink(userEmail) {
  if (userEmail) {
    const userAlias = userEmail.substring(0, userEmail.indexOf("@"));
    return "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + userAlias;
  }
  return "";
}
export function getSalesforceAccountLink(accountId) {
  if (accountId) {
    if (getEnvironment() === "prod") {
      return "https://ams-amazon.my.salesforce.com/lightning/r/Account/" + accountId + "/view";
    } else {
      return "https://ams-amazon--beta.sandbox.lightning.force.com/lightning/r/Account/" + accountId + "/view";
    }
  }
  return "";
}
