import { defaultTheme } from "@amzn/storm-ui";
import React from "react";
import styled from "styled-components";

import { filterPropsByPrefix, getHorizontalDirection, parseSpacing } from "../util/util";

const gridColumns = 12;

// Turns a raw width value (a string or number) into an object with fields
// containing the type of width and the parsed width values
const parseWidth = width =>
  typeof width === "string" && /^grid-/.test(width)
    ? {
        type: "grid",
        // Turns "grid-X" into a numeric percentage w/ four significant digits (e.g.
        // "grid-4" to 0.3333)
        value: Math.floor((10000 * parseInt(width.substr(5))) / gridColumns) / 10000,
      }
    : typeof width === "string" && /^[0-9.]+%\s*$/.test(width)
      ? {
          type: "percentage",
          // Turns "X%" into a numeric percentage w/ four significant digis (e.g. "33.33%"
          // to 0.3333)
          value: Math.floor(100 * parseFloat(width)) / 10000,
        }
      : width === "fit" || width === "fill"
        ? { type: "flex", value: width }
        : width !== undefined && width !== null && width !== ""
          ? { type: "css", value: width }
          : { type: "empty", value: null };

const generateWidthProperty = (width, horizontalSpacing, wrap) => {
  return width.type === "grid" || width.type === "percentage"
    ? `calc(${width.value * 100}% - ${
        parseInt(horizontalSpacing) - (wrap === "none" ? width.value * parseInt(horizontalSpacing) : 0)
      }px) !important`
    : width.type === "css"
      ? `${width.value}${typeof width.value === "number" ? "px" : ""} !important`
      : undefined;
};

export const Parent = styled.div`
  > * {
    box-sizing: border-box !important;
    margin: 0 ${props => props.horizontalSpacing} ${props => (props.wrap === "none" ? 0 : props.verticalSpacing)} 0 !important;
    flex: ${props => {
      const width = parseWidth(props.widths);

      return width.type === "flex" && width.value === "fill" ? "1 !important" : undefined;
    }};
    flex-shrink: ${props => {
      const width = parseWidth(props.widths);

      return width.type === "grid" ||
        width.type === "percentage" ||
        (width.type === "flex" && width.value === "fit") ||
        (width.type === "css" && width.value !== "auto")
        ? `0 !important`
        : undefined;
    }};
    width: ${props => {
      if (Array.isArray(props.widths)) {
        return undefined;
      }
      const width = parseWidth(props.widths);

      return generateWidthProperty(width, props.horizontalSpacing, props.wrap);
    }};
  }
  > *:last-child {
    margin-right: ${props => (props.wrap === "none" ? "0 !important" : "")};
  }
  ${props => {
    if (!Array.isArray(props.widths)) {
      return undefined;
    }

    return props.widths.reduce((result, width, index) => {
      return `${result}
      > *:nth-child(${parseInt(index) + 1}){
        width: ${generateWidthProperty(parseWidth(width), props.horizontalSpacing, props.wrap)}
      }`;
    }, ``);
  }}
`;

class Row extends React.Component {
  getBackgroundColor() {
    const { backgroundColor, type } = this.props;
    if (backgroundColor === "primary") {
      return defaultTheme.palette.white;
    } else if (backgroundColor === "secondary") {
      return defaultTheme.palette.gray[10];
    } else {
      if (type === "fill" && !backgroundColor) {
        return defaultTheme.palette.gray[10];
      } else {
        return backgroundColor;
      }
    }
  }

  render() {
    const {
      alignmentHorizontal = "start",
      alignmentVertical = "center",
      children,
      className,
      height,
      maxHeight,
      minHeight,
      maxWidth,
      minWidth,
      spacing = "medium",
      spacingInset,
      type,
      widths,
      wrap = "none",
    } = this.props;

    const spacingValues = parseSpacing(spacing);
    const verticalSpacing = spacingValues[0];
    const horizontalSpacing = spacingValues[1] || verticalSpacing;

    const currentWrap = height === undefined ? wrap : "none";
    const rowStyles = {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      backgroundColor: this.getBackgroundColor(),
      height,
      maxHeight,
      minHeight,
      maxWidth,
      minWidth,
      width: currentWrap === "none" ? undefined : `calc(100% + ${horizontalSpacing}px)`,
      flexWrap: wrap === "down" ? "wrap" : wrap === "up" ? "wrap-reverse" : undefined,
      alignItems:
        {
          top: "flex-start",
          bottom: "flex-end",
        }[alignmentVertical] || alignmentVertical,
      justifyContent:
        {
          left: "flex-start",
          right: "flex-end",
          justify: "space-between",
        }[getHorizontalDirection(alignmentHorizontal)] || getHorizontalDirection(alignmentHorizontal),
      margin: currentWrap === "none" ? undefined : `0 -${horizontalSpacing}px -${verticalSpacing}px 0`,
      padding: parseSpacing(spacingInset).join(" "),
    };

    if (type === "outline") {
      rowStyles.borderRadius = "4px";
      rowStyles.border = `1px solid ${defaultTheme.palette.gray[300]}`;
    }

    return currentWrap === "none" ? (
      <Parent
        className={className}
        style={rowStyles}
        horizontalSpacing={horizontalSpacing}
        verticalSpacing={verticalSpacing}
        wrap={currentWrap}
        widths={widths}
        {...filterPropsByPrefix(this.props, ["aria-", "data-"])}
      >
        {children}
      </Parent>
    ) : (
      <div
        className={className}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
        }}
      >
        <Parent
          style={{ ...rowStyles, width: "100%" }}
          wrap={currentWrap}
          horizontalSpacing={horizontalSpacing}
          verticalSpacing={verticalSpacing}
          widths={widths}
          {...filterPropsByPrefix(this.props, ["aria-", "data-"])}
        >
          {children}
        </Parent>
      </div>
    );
  }
}

export default Row;
