import { createBrowserHistory } from "history";
import { TelemetryClientInterface, UrlParser } from "../types";

type ParsedUrl = {
  pageId: string | null;
  pageAttributes: Record<string, string>;
};

export const defaultUrlParser: UrlParser = (url: URL) => {
  // Extract the pageId from the pathname
  const pageId = `${url.pathname}${url.hash}`;
  // Extract pageAttributes from the searchParams
  const pageAttributes: ParsedUrl["pageAttributes"] = {};
  url.searchParams.forEach((value, key) => {
    pageAttributes[key] = value;
  });

  return { pageId, pageAttributes };
};

const getCurrentUrl = () => {
  try {
    return new URL(window.location.href);
  } catch (error) {
    return null;
  }
};

/**
 * Captures initial page view and listens to URL changes.
 */
export const monitorPageViews = (telemetryClient: TelemetryClientInterface) => {
  const urlParser = telemetryClient.config.urlParser ?? defaultUrlParser;
  const url = getCurrentUrl();
  if (!url) {
    return;
  }
  let parsedPageUrl: ReturnType<UrlParser> = null;
  try {
    parsedPageUrl = urlParser(url);
  } catch (error) {
    console.error(`Failed to parse URL ${url}`, error);
  }
  if (parsedPageUrl) {
    const { pageId, pageAttributes } = parsedPageUrl;
    const { siteName, serviceName } = telemetryClient.config;
    const pageTag = [siteName, serviceName].filter(Boolean).join(".");
    telemetryClient.publishPageView(
      pageTag ? `/${pageTag}${pageId}` : pageId,
      pageAttributes
    );
  }
  const history = createBrowserHistory();
  history.listen(() => {
    const url = getCurrentUrl();
    if (!url) {
      return;
    }
    let parsedPageUrl: ReturnType<UrlParser> = null;
    try {
      parsedPageUrl = urlParser(url);
    } catch (error) {
      console.error(`Failed to parse URL ${url}`, error);
    }
    if (parsedPageUrl) {
      const { pageId, pageAttributes } = parsedPageUrl;
      telemetryClient.publishPageView(pageId, pageAttributes);
    }
  });
};
