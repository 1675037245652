const serviceRootDNS = "acfportal.advertising.amazon.dev";
export const AuthUserPoolConfig = {
  dev: {
    region: "us-east-1",
    userPoolId: "us-east-1_558k2t1uf",
    userPoolWebClientId: "3mbjimbqlt5u11shb7uibriisj",
    oauth: {
      domain: "cog-beta-ads-sales-unity-test.auth.us-east-1.amazoncognito.com",
      scopes: ["openid", "email", "profile"],
      redirectSignIn: "http://localhost:3000",
      redirectSignOut: "http://localhost:3000",
      responseType: "code",
    },
  },
  beta: {
    region: "us-east-1",
    userPoolId: "us-east-1_558k2t1uf",
    userPoolWebClientId: "3mbjimbqlt5u11shb7uibriisj",
    oauth: {
      domain: "cog-beta-ads-sales-unity-test.auth.us-east-1.amazoncognito.com",
      scopes: ["openid", "email", "profile"],
      redirectSignIn: `https://beta.${serviceRootDNS}`,
      redirectSignOut: `https://beta.${serviceRootDNS}`,
      responseType: "code",
    },
  },
  gamma: {
    region: "us-east-1",
    userPoolId: "us-east-1_6oWelizdg",
    userPoolWebClientId: "3m61j5ivn8cd1ffnbidsoela35",
    oauth: {
      domain: "cog-gamma-ads-sales-unity.auth.us-east-1.amazoncognito.com",
      scopes: ["openid", "email", "profile"],
      redirectSignIn: `https://gamma.${serviceRootDNS}`,
      redirectSignOut: `https://gamma.${serviceRootDNS}`,
      responseType: "code",
    },
  },
  prod: {
    region: "us-east-1",
    userPoolId: "us-east-1_jF5DsQymr",
    userPoolWebClientId: "7g3kvcvl2saupg7j90ngv8l27v",
    oauth: {
      domain: "cog-prod-ads-sales-unity.auth.us-east-1.amazoncognito.com",
      scopes: ["openid", "email", "profile"],
      redirectSignIn: `https://${serviceRootDNS}`,
      redirectSignOut: `https://${serviceRootDNS}`,
      responseType: "code",
    },
  },
};

export default AuthUserPoolConfig;
