import { MetricsConfig, MetricsProviderInterface } from "../types";
import * as KatalMetrics from "@amzn/katal-metrics";
import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import { COUNTERS, OPS_METRICS_TYPES } from "../constants";

/**
 * A MetricsProvider class that publishes metrics using the Katal Metrics sushi driver.
 * Provided properties are added as relatedMetrics to the Katal Metrics context.
 * All metrics are published to PMET and Andes.
 */
export class KatalMetricsSushiProvider implements MetricsProviderInterface {
  private initialMetricsPublisher: KatalMetrics.Publisher;
  private sharedContext: MetricsConfig["sharedContext"];
  private pmetServiceName: string;
  private pmetSiteName: string;
  private metricsDriver: KatalMetricsDriverSushi;
  private metricsErrorHandler: MetricsConfig["metricsErrorHandler"];
  constructor({
    siteName,
    serviceName,
    sharedContext,
    stage,
    metricsErrorHandler,
    region,
    onBeforeUnload,
  }: MetricsConfig) {
    this.sharedContext = sharedContext;
    this.pmetServiceName = `${serviceName}:${region}:${stage}`;
    this.pmetSiteName = siteName;
    let driver = new KatalMetricsDriverSushi.Builder().withDomainRealm(
      "prod",
      "USAmazon"
    );
    if (metricsErrorHandler) {
      driver = driver.withErrorHandler(metricsErrorHandler);
    }
    this.metricsDriver = driver.build();

    if (metricsErrorHandler) {
    }
    this.metricsErrorHandler = metricsErrorHandler;

    const initialMetricsContext = this.createMetricsContext();

    this.initialMetricsPublisher = new KatalMetrics.Publisher(
      this.metricsDriver,
      this.metricsErrorHandler,
      initialMetricsContext
    );
    this.metricsDriver.beforeUnload(() => {
      onBeforeUnload?.();
    });
  }

  publishCounter = (
    operation: string,
    metricName: string,
    count: number,
    dimensions?: Record<string, string>
  ) => {
    const publisher = this.createActionPublisher(operation, dimensions);
    publisher.publishCounterMonitor(metricName, count);
  };

  publishTimer = (
    operation: string,
    metricName: string,
    count: number,
    dimensions?: Record<string, string>
  ) => {
    const publisher = this.createActionPublisher(operation, dimensions);
    publisher.publishTimerMonitor(metricName, count);
  };

  publishPageView = (
    pageId: string,
    pageAttributes?: Record<string, string>
  ) => {
    const publisher = this.createActionPublisher(COUNTERS.PageVisit, {
      pageId,
      ...pageAttributes,
    });
    publisher.publishCounterMonitor(OPS_METRICS_TYPES.Count, 1);
  };

  private createActionPublisher = (
    methodName: string,
    additionalContext?: Record<string, string>
  ) => {
    if (!additionalContext) {
      const actionMetricsPublisher =
        this.initialMetricsPublisher.newChildActionPublisherForMethod(
          methodName
        );
      return actionMetricsPublisher;
    } else {
      const metricsPublisher =
        this.createMetricsPublisher(
          additionalContext
        ).newChildActionPublisherChainedForMethod(methodName);
      return metricsPublisher;
    }
  };

  private createMetricsContext = (
    additionalContext?: MetricsConfig["sharedContext"]
  ) => {
    const contextProperties = {
      ...this.sharedContext,
      ...additionalContext,
    };
    const relatedMetrics = Object.entries(contextProperties).map(
      ([key, value]) => {
        return new KatalMetrics.Metric.String(key, value);
      }
    );
    const metricsContext = new KatalMetrics.Context.Builder()
      .withSite(this.pmetSiteName)
      .withServiceName(this.pmetServiceName)
      .withRelatedMetrics(...relatedMetrics)
      .build();
    return metricsContext;
  };

  private createMetricsPublisher = (
    additionalContext?: MetricsConfig["sharedContext"]
  ) => {
    const metricsContext = this.createMetricsContext(additionalContext);
    const metricsPublisher = new KatalMetrics.Publisher(
      this.metricsDriver,
      this.metricsErrorHandler,
      metricsContext
    );
    return metricsPublisher;
  };
}
