import { COUNTERS, OPS_METRICS_TYPES, TIMERS } from "../constants";
import { TelemetryClientInterface } from "../types";

/**
 * Captures time spent actively using the current tab.
 * A timer metric is emitted whenever the tab loses focus and started again when the tab is re-focused
 */
export const monitorActiveTimeSpent = (
  telemetryClient: TelemetryClientInterface
) => {
  telemetryClient.startTimer(TIMERS.ActiveTimeSpent);
  telemetryClient.publishCounter(
    COUNTERS.UserActiveStart,
    OPS_METRICS_TYPES.Count,
    1
  );

  window.addEventListener("blur", function () {
    telemetryClient.stopTimer(TIMERS.ActiveTimeSpent);
  });

  window.addEventListener("focus", function () {
    telemetryClient.publishCounter(
      COUNTERS.UserActiveStart,
      OPS_METRICS_TYPES.Count,
      1
    );
    telemetryClient.startTimer(TIMERS.ActiveTimeSpent);
  });

  window.addEventListener("beforeunload", function () {
    telemetryClient.stopTimer(TIMERS.ActiveTimeSpent); // Ensure the last active period is counted
  });
};
