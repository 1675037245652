import React from "react";
import { Card } from "@amzn/storm-ui";
import {
  PanelGroup,
  Pagination,
  FooterPanelContainer,
  CurrencyCellRenderer,
  udcMultiSelectWithSearchFilter,
} from "@amzn/unified-data-table-components/pack";
import Loader from "../../components/Loader";
import { Table } from "../../components/unified-data-table/unified-data-table";
import { formatDateTime, processFeedbacks } from "../../util/util";
import { useHistory } from "react-router-dom";
import { CHARACTER_LIMIT, PAGINATION_CONSTANT } from "../../util/constants";
import MoreTextInCell from "../list-page/MoreTextInCell";

const AssociatedFeedbacks = props => {
  const { associatedFeedbacks, childFeedbackLoading } = props;
  const currency = "USD";
  const history = useHistory();
  let associatedFeedbacksData = [];
  const associatedFeedbacksCopy = associatedFeedbacks.filter(fb => fb !== null);
  console.log(associatedFeedbacksCopy);
  if (associatedFeedbacksCopy.length > 0) {
    associatedFeedbacksData = processFeedbacks(associatedFeedbacksCopy) || [];
  }
  // const associatedFeedbacks = [...associatedFeedbacksCopy,...associatedFeedbacksCopy,...associatedFeedbacksCopy,...associatedFeedbacksCopy]

  const columnDefs = [
    {
      colId: "accountName",
      field: "accountName",
      sortable: true,
      resizable: true,
      width: 180,
      minWidth: 170,
      maxWidth: 170,
      headerName: "Account name",
    },
    {
      colId: "createdTimestamp",
      field: "createdTimestamp",
      headerName: "Submitted date",
      sortable: true,
      valueFormatter: params => formatDateTime(params.data.createdTimestamp),
    },
    {
      colId: "themeAssociationDate",
      field: "themeAssociationDate",
      headerName: "Theme Association date",
      sortable: true,
      hide: true,
      valueFormatter: params => formatDateTime(params.data.themeAssociationDate),
    },
    {
      colId: "customerVerbatim",
      field: "customerVerbatim",
      maxWidth: 300,
      headerName: "Customer quote",
      cellRenderer: "MoreTextInCell",
    },
    {
      colId: "advertisingSubProduct",
      field: "advertisingSubProduct",
      headerName: "Product/Service",
      sortable: true,
      width: 280,
      maxWidth: 300,
    },
    {
      colId: "submittedByName",
      field: "submittedByName",
      sortable: true,
      headerName: "Submitted by",
    },
    {
      colId: "revenueImpacted",
      field: "revenueImpacted",
      sortable: true,
      headerName: "Blocked revenue",
      cellRenderer: CurrencyCellRenderer,
      cellRendererParams: {
        getFormattedValue: (value, params) =>
          value === null || value === undefined
            ? "EMPTY_CELL_VALUE"
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
              }).format(value),
      },
      filter: "agNumberColumnFilter",
    },
    {
      colId: "category",
      field: "category",
      sortable: true,
      headerName: "Feedback type",
    },
    {
      colId: "subCategory",
      field: "subCategory",
      sortable: true,
      headerName: "Feedback category",
      filter: udcMultiSelectWithSearchFilter,
    },
    {
      colId: "updateSource",
      field: "updateSource",
      sortable: true,
      headerName: "Theme mapping source",
      valueFormatter: params => {
        return params.data.updateSource === "dataScience" ? "Sales AI" : "ACF portal";
      },
      hideWhenResetToDefault: true,
    },
  ];

  const onGridReady = async event => {
    const { api } = event;
    // 1. For client side row model, you need to explicitly decide when to show loading overlay
    api.showLoadingOverlay();

    api.setRowData(associatedFeedbacksData);
  };

  const footerPanelElement = (
    <FooterPanelContainer>
      <PanelGroup align="end">
        <Pagination pageSizes={PAGINATION_CONSTANT} />
      </PanelGroup>
    </FooterPanelContainer>
  );

  if (childFeedbackLoading) {
    return <Loader />;
  }

  if (associatedFeedbacksData.length === 0) {
    return <Card>No associated feedbacks.</Card>;
  }
  return (
    <Table
      onGridReady={onGridReady}
      frameworkComponents={{
        MoreTextInCell: MoreTextInCell,
      }}
      gridOptions={{
        animateRows: true,
        columnDefs,
        pagination: true,
        paginationPageSize: 50,
        getRowId: rowData => {
          return rowData.data.id;
        },
        defaultColDef: {
          wrapText: true,
          autoHeight: true,
        },
        rowSelection: "single",
        isRowSelectable: rowNode => true,
        getRowStyle: params => {
          return { cursor: "pointer" };
        },
        enableCellTextSelection: true,
        onCellClicked: params => {
          const allRowdata = [];
          params.api.forEachNodeAfterFilter(node => {
            allRowdata.push(node.data);
          });
          console.log(allRowdata);
          let characterLimit = params?.column?.colDef?.characterLimit
            ? params.column.colDef.characterLimit
            : CHARACTER_LIMIT;
          let cellClicked = params.column.colId;
          let colsToIgnoreOnCellClicked = ["customerVerbatim"];
          let clickIgnore = colsToIgnoreOnCellClicked.includes(cellClicked) && params?.value?.length > characterLimit;
          if (params.column.colId === "checkboxCol" || clickIgnore) {
            return;
          } else {
            //TODO: change route path name
            history.push("/feature/" + params.node.data.id, {
              feedbackArray: allRowdata,
              rowIndex: params.rowIndex,
            });
          }
        },
      }}
      tableFooter={footerPanelElement}
    />
  );
  // return (
  //     <>
  //             {feedbacksSlider.map(feedback => {
  //                 if(!feedback){
  //                     return (
  //                         <Card>
  //                             No associated feedbacks.
  //                         </Card>
  //                     )
  //                 }
  //                 const { fields: {customerVerbatim, advertisingSubProduct} } = feedback
  //             return (
  //                 <Card>
  //                 <>
  //                     <ObjectRow
  //                         divider = {false}
  //                         columnData={[
  //                             {
  //                                 dataKey: <Text type="span">Product service:</Text>,
  //                                 dataValue: <Text type="span">{advertisingSubProduct}</Text>
  //                             },
  //                         ]}
  //                     />
  //                     <ObjectRow
  //                         divider = {false}
  //                         columnData={[
  //                             {
  //                                 dataKey: <Text type="span">Customer quote:</Text>,
  //                                 dataValue: <Text type="span">{customerVerbatim}</Text>
  //                             },
  //                         ]}
  //                     />
  //                     {/* <Divider/> */}
  //                 </>
  //             </Card>
  //             )})
  //             }
  //         <Pagination
  //             id="example-pagination"
  //             currentPage={currentPage}
  //             currentSize={currentSize}
  //             totalPages={totalPages}
  //             pageSizes={[1,2,3,4,5]}
  //             onSetPageSize={(val)=>{
  //                 setCurrentSize(val);
  //                 setCurrentPage(0);
  //             }}
  //             setCurrentPage={(val)=>{
  //                 console.log(currentPage, currentSize);
  //                 setCurrentPage(val)
  //             }}
  //         />
  //     </>
  // )
};

export default AssociatedFeedbacks;
