import React from "react";
import SubmitFeedbackPage from "./SubmitFeedbackPage";
import { Modal } from "@amzn/storm-ui";
import { withRouter } from "react-router-dom";

class SubmitPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: true,
      openConfirmationModal: false,
    };
    this.setOpenConfirmationModal = this.setOpenConfirmationModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  closeModal() {
    this.setState({
      openConfirmationModal: !this.state.openConfirmationModal,
    });
  }

  setOpenConfirmationModal(val) {
    this.setState({
      openConfirmationModal: val,
    });
  }

  render() {
    const { header, mode } = this.props;
    return (
      <Modal header={header} onClose={this.closeModal} isOpen={this.state.isModalOpen}>
        <SubmitFeedbackPage
          {...this.props}
          setOpenConfirmationModal={this.setOpenConfirmationModal}
          openConfirmationModal={this.state.openConfirmationModal}
          mode={mode}
          onClose={this.closeModal}
        />
      </Modal>
    );
  }
}

export default withRouter(SubmitPage);
