import { OPS_METRICS_TYPES, ERRORS } from "../constants";
import { TelemetryClientInterface } from "../types";

const getErrorMessageAsString = (message: string | Event) => {
  try {
    return message?.toString();
  } catch (error) {
    return `Failed to parse error message as string`;
  }
};
/**
 * Listens to errors and unhandled promise rejections in the browser
 * and reports them using the provided logger and telemetryClient publisher.
 */
export function monitorAndReportErrors(
  telemetryClient: TelemetryClientInterface
): void {
  const TAG = "MonitorAndReportErrors";
  const logger = telemetryClient.getLogger(TAG);
  // Listen for runtime errors
  window.onerror = (message, source, lineno, colno, error) => {
    logger.error(`[${TAG}] Runtime error: `, {
      message,
      source,
      lineno,
      colno,
      error,
    });

    telemetryClient.publishCounter(
      ERRORS.UncaughtRuntimeError,
      OPS_METRICS_TYPES.Count,
      1,
      {
        errorMessage: getErrorMessageAsString(message),
        errorSource: source ?? "",
        errorLineNo: `${lineno ?? ""}`,
        errorColNo: `${colno ?? ""}`,
        url: window.location.href,
      }
    );
  };

  // Listen for unhandled promise rejections
  window.onunhandledrejection = (event) => {
    logger.error(
      `[${TAG}] Runtime error: Unhandled promise rejection caught:`,
      event.reason,
      event
    );
    telemetryClient.publishCounter(
      ERRORS.UnhandledPromiseRejection,
      OPS_METRICS_TYPES.Count,
      1,
      {
        errorMessage: getErrorMessageAsString(event.reason),
        url: window.location.href,
      }
    );
  };
}
