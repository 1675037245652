import React from "react";
import { InlineMessage } from "@amzn/storm-ui";

const ReviewMappingCustomBulkActionComponent = props => {
  return (
    <React.Fragment>
      <span>Are you sure you want to {props.approvalAction}?</span>
      <InlineMessage messageType="warning" message={props.warningMessage} />
    </React.Fragment>
  );
};

export default ReviewMappingCustomBulkActionComponent;
