import { TelemetryClient } from "./telemetry-client";

export interface Logger {
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
  getLogger: (tag: string) => Omit<Logger, "getLogger">;
}

export interface MetricsConfig {
  /**
   * Your organization name: e.g. ASX
   */
  siteName: string;
  /**
   * Your application name: e.g. MBoB
   */
  serviceName: string;
  /**
   * Any dimensions that you would like persist with each metric. e.g. { sessionId: "XXXX0" }
   */
  sharedContext: Record<string, any>;
  /**
   * Current stage, can be any string. e.g. "dev" | "test" | "prod"
   */
  stage: string;
  /**
   * Current region, can be any string. Usually "USAmazon" | "JPAmazon" | "EUAmazon" but can be anything
   */
  region: string;
  /**
   * Only for KatalMetrics sink. Function used by KatalMetrics sink when an internal error occurs.
   */
  metricsErrorHandler?: (error: any) => void; // Function that is called on internal metrics error
  /**
   * Only for KatalMetrics sink. Function called before the user navigates away or closes the page. Use it to stop a timer or emit some final metrics.
   */
  onBeforeUnload?: () => void;
  /**
   * Function that parses the current URL and returns a pageId and pageAttributes. e.g. (url: URL) => ({ pageId: "some-id", pageAttributes: { some: "attribute" } })
   * This is particularly useful if you have dynamic URLs and would like to analyze which page views are being visited and group them.
   */
  urlParser?: UrlParser;
  /**
   * Decides whether to include the obfuscated customer id in the shared context. Defaults to false.
   */
  isAnonymous?: boolean;
}

export type TelemetryPlugin = (client: TelemetryClient) => void;

export interface TelemetryClientConstructorParams {
  config: MetricsConfig;
  logger?: Logger;
  metrics: {
    provider: MetricsProviderInterface;
    plugins?: TelemetryPlugin[];
  }[];
}

export type Dimensions = Record<string, string>;

export type UrlParser = (
  url: URL
) => { pageId: string; pageAttributes?: Dimensions } | null;

export abstract class MetricsProviderInterface {
  constructor(_params: MetricsConfig) {}
  publishTimer: (
    methodName: string,
    timerName: string,
    count: number,
    dimensions?: Dimensions
  ) => void;
  publishCounter: (
    methodName: string,
    counterName: string,
    value: number,
    dimensions?: Dimensions
  ) => void;
  publishPageView: (pageId: string, pageAttributes?: Dimensions) => void;
}

export interface TelemetryClientInterface {
  config: MetricsConfig;
  /**
   * Records the current time for the provided scope
   */
  startTimer: (operation: string, dimensions?: Dimensions) => void;
  /**
   * If a timer was already started, clear the state and publish the timer metric
   * Return the time spent for this timer
   */
  stopTimer: (
    operation: string,
    additionalParams?: { dimensions?: Dimensions; newOperationName?: string }
  ) => number;
  /**
   * Remove the current timer from the state without emitting any metric
   */
  abortTimer: (operation: string) => void;
  publishCounter: (
    operation: string,
    counterName: string,
    count: number,
    dimensions?: Dimensions
  ) => void;
  publishTimer: (
    operation: string,
    time: number,
    dimensions?: Dimensions
  ) => void;
  publishPageView: (pageId: string, pageAttributes?: Dimensions) => void;
  /**
   * Get a map containing all the current active timers
   */
  getTimers: () => Map<string, { value: number; dimensions?: Dimensions }>;
  /**
   * Get the configured logger
   */
  getLogger: (tag: string) => ReturnType<Logger["getLogger"]>;
  /**
   * Start capturing metrics like errors, active time spent, web vitals...
   */
  start: () => void;
}
