import React, { useState, useEffect } from "react";
import { Text, Modal, FileUploadButton, InlineMessage, UnorderedList, ListItem, Link } from "@amzn/storm-ui";
import { useHistory } from "react-router";
import Column from "../../../components/column";
import apiClient from "../../../util/apiClient";
import Logger, { logPageMetric } from "../../../util/logger";
import Papa from "papaparse";
import { getStaticData, createEMFObject } from "../../../util/util";
import { useStore } from "../../../store";
import endpoints from "../../../urlConfig";
import { WHY_IS_THIS_IMPORTANT_DROPDOWN_ITEMS } from "../../../util/constants";
import { Page } from "../../../typings/enum";
import ErrorAlert from "../../../components/error-alert/ErrorAlert";

const AdminBulkUpload = props => {
  const history = useHistory();
  const [attachmentValidationError, setAttachmentValidationError] = useState({});
  const headers = new Set([
    "Customer Feedback: Owner Alias",
    "Account Name: Account Name",
    "Account ID",
    "Customer Quote",
    "Blocked Revenue",
    "Sentiment",
    "Feedback Type",
    "Feedback Category",
    "Product/Service",
    "Additional Context",
    "Why is this important",
    "Opportunity Name",
    "Customer Contact",
    "Create Date",
    "Label",
    "Source",
    "Customer Feedback: Owner Email",
    "Opportunity Id",
  ]);
  const requiredFields = new Set([
    "Customer Feedback: Owner Alias",
    "Account Name: Account Name",
    "Account ID",
    "Customer Quote",
    "Blocked Revenue",
    "Sentiment",
    "Feedback Type",
    "Feedback Category",
    "Product/Service",
    "Source",
    "Customer Feedback: Owner Email",
    "Why is this important",
  ]);
  const mailformat = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  const allowWholeNumber = new RegExp(/^\+?(0|[1-9]\d*)$/);
  const staticData = useStore(state => state.staticData);
  const [feedbackTypes, setFeedbackTypes] = useState(new Set([]));
  const [feedbackCategories, setFeedbackCategories] = useState(new Set([]));
  const [feedbackProductServices, setFeedbackProductServices] = useState(new Set([]));
  const [invalidEntries, setInvalidEntries] = useState(false);
  const [error, setIsError] = useState(false);

  useEffect(() => {
    setFeedbackTypes(new Set(getStaticData("FEEDBACK_TYPE", "", staticData)));
    setFeedbackCategories(new Set(getStaticData("FEEDBACK_CATEGORY", "", staticData)));
    setFeedbackProductServices(new Set(getStaticData("PRODUCT_SERVICES", "", staticData)));
    Logger.sessionInfo("Bulk Upload Visited", {
      emfLog: createEMFObject("PageVisits", 1, "Count", {
        PageVisits: "AdminBulkUploadVisits",
      }),
    });
    logPageMetric(Page.BulkUpload);
    // eslint-disable-next-line
  }, []);

  const putToS3Bucket = async (url, data) => {
    let formData = new FormData();
    formData.append("data", data);
    try {
      await fetch(url, {
        method: "Put",
        headers: {
          "Content-Type": `${data.type}`,
        },
        body: Buffer.from(data.data, "base64"),
      });
      Logger.sessionInfo("Bulk upload file successfully uploaded to s3 ", {
        emfLog: createEMFObject("BulkUploads", 1, "Count"),
      });
    } catch (err) {
      Logger.sessionError(
        `failed to upload file ${JSON.stringify({ name: data.metaData.name, type: data.metaData.type, size: data.metaData.size })} to s3 - ${err}`
      );
      setIsError(true);
    }
  };

  const uploadFilesThroughPresignedUrl = async (resp, fileUploaded) => {
    if (resp?.data?.opId) {
      const metaData = {
        name: fileUploaded.name,
        type: fileUploaded.type,
        size: fileUploaded.size,
      };
      try {
        metaData["fileData"] = await new Blob([fileUploaded], metaData).arrayBuffer();
      } catch (err) {
        Logger.sessionError(`unable to convert to array Buffer, ${err}`);
        setIsError(true);
      }

      const clientUrl = resp?.data?.opId;
      await putToS3Bucket(clientUrl, {
        data: metaData["fileData"],
        type: metaData["type"],
        metaData: metaData,
      });
    }
  };

  const requiredHeadersPresent = attachedHeaders => {
    const requiredHeaders = new Set([
      "Customer Feedback: Owner Alias",
      "Account Name: Account Name",
      "Account ID",
      "Customer Quote",
      "Blocked Revenue",
      "Sentiment",
      "Feedback Type",
      "Feedback Category",
      "Product/Service",
      "Additional Context",
      "Why is this important",
      "Opportunity Name",
      "Customer Contact",
      "Create Date",
      "Label",
      "Source",
      "Customer Feedback: Owner Email",
    ]);
    for (const headerVal of attachedHeaders[0]) {
      if (requiredHeaders.has(headerVal)) {
        requiredHeaders.delete(headerVal);
      }
    }
    if (requiredHeaders.size > 0) {
      const headersMissing = Array.from(requiredHeaders).join(" ");
      setAttachmentValidationError({
        name: `Missing required headers ${headersMissing}`,
      });
      return false;
    }
    return true;
  };

  const requiredFieldCheck = feedbackJson => {
    for (const [feedbackField, value] of Object.entries(feedbackJson)) {
      if (requiredFields.has(feedbackField) && !value) {
        setAttachmentValidationError({
          name: `${feedbackField} is a required field.`,
        });
        return true;
      }
    }
    return false;
  };

  const invalidUserEmail = email => {
    if (!email.match(mailformat)) {
      setAttachmentValidationError({
        name: `${email} is not a valid email address`,
      });
      return true;
    }
    return false;
  };

  const invalidBlockedRevenue = revenue => {
    if (isNaN(revenue) || !allowWholeNumber.test(revenue.trim())) {
      setAttachmentValidationError({
        name: `${revenue} is not a valid Blocked Revenue entry. Please enter only positive whole number without currency symbol`,
      });
      return true;
    }
    return false;
  };

  const invalidWhyThisImportant = whyThisImportant => {
    const why_this_imp_lowercase = WHY_IS_THIS_IMPORTANT_DROPDOWN_ITEMS.map(field => field.toLowerCase());
    if (!why_this_imp_lowercase.includes(whyThisImportant.toLowerCase())) {
      setAttachmentValidationError({
        name: `${whyThisImportant} is not a valid Why is this important entry. Valid entries are: ${[...WHY_IS_THIS_IMPORTANT_DROPDOWN_ITEMS]}`,
      });
      return true;
    }
    return false;
  };

  const invalidFeedbackType = type => {
    if (!feedbackTypes.has(type)) {
      setAttachmentValidationError({
        name: `${type} is not a valid Feedback Type entry`,
      });
      return true;
    }
    return false;
  };

  const invalidFeedbackCategory = category => {
    if (!feedbackCategories.has(category)) {
      setAttachmentValidationError({
        name: `${category} is not a valid Feedback Category entry`,
      });
      return true;
    }
    return false;
  };

  const invalidFeedbackProductService = services => {
    const productServieEntries = services.split(",");
    for (let i = 0; i < productServieEntries.length; i++) {
      const productService = productServieEntries[i].trim();
      if (!feedbackProductServices.has(productService)) {
        setAttachmentValidationError({
          name: `${productService} is not a valid Feedback Product/Service entry`,
        });
        return true;
      }
    }
    return false;
  };

  const onUpload = e => {
    const file = e.target.files[0];
    if (file && validateFileName(file) && validateFileSize(file) && validateFileType(file)) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];

          // Iterating data to get column name and their values
          for (let i = 0; i < results.data.length; i++) {
            const row = results.data[i];
            rowsArray.push(Object.keys(row));
            valuesArray.push(Object.values(row));
            if (!requiredHeadersPresent(rowsArray)) {
              setInvalidEntries(true);
              return;
            }
            if (
              requiredFieldCheck(row) ||
              invalidUserEmail(row["Customer Feedback: Owner Email"]) ||
              invalidBlockedRevenue(row["Blocked Revenue"]) ||
              invalidFeedbackType(row["Feedback Type"]) ||
              invalidFeedbackCategory(row["Feedback Category"]) ||
              invalidFeedbackProductService(row["Product/Service"]) ||
              invalidWhyThisImportant(row["Why is this important"])
            ) {
              setInvalidEntries(true);
              return;
            }
          }
          for (let i = 0; i < rowsArray[0].length; i++) {
            const header = rowsArray[0][i];
            if (!headers.has(header)) {
              Logger.sessionError(`Invalid header passed in file ${header}`);
              setAttachmentValidationError({
                name: `Invalid header passed in file ${header}`,
              });
              setInvalidEntries(true);
              return;
            }
            Logger.sessionInfo("All Headers are Valid");
          }

          if (!invalidEntries) {
            const submitObj = {
              entityType: "bulkUpload",
              input: {
                fields: {
                  fileName: file.name,
                  contentType: file.type,
                },
              },
            };
            const { staticOperations } = endpoints;
            apiClient
              .post(staticOperations, submitObj)
              .then(res => {
                uploadFilesThroughPresignedUrl(res, file);
                Logger.sessionInfo("Successfully uploaded bulk upload file", {
                  emfLog: createEMFObject("FeedbackBulkUploadCounts", 1, "Count"),
                });
                history.push("/admin");
              })
              .catch(err => {
                Logger.sessionError(`Error Uploading File ${err}`, {
                  emfLog: createEMFObject(`BulkUploadError`, 1, "Count"),
                });
                setIsError(true);
              });
          }
        },
      });
    }
  };

  const validateFileName = file => {
    if (file.name.length > 50) {
      setAttachmentValidationError({
        name: "File name too long",
      });
      return false;
    }
    const attachmentValidationErrorState = Object.assign({}, attachmentValidationError);
    delete attachmentValidationErrorState["name"];
    setAttachmentValidationError(attachmentValidationErrorState);
    return true;
  };

  const validateFileType = file => {
    if (file.type !== "text/csv") {
      setAttachmentValidationError({
        type: "Invalid file type! Please select a .csv file",
      });
      return false;
    }
    const attachmentValidationErrorState = Object.assign({}, attachmentValidationError);
    delete attachmentValidationErrorState["type"];
    setAttachmentValidationError(attachmentValidationErrorState);
    return true;
  };

  const validateFileSize = file => {
    if (file.size > 7000000) {
      setAttachmentValidationError({
        size: "Upload a file with a max size of 14 MB",
      });
      return false;
    }
    const attachmentValidationErrorState = Object.assign({}, attachmentValidationError);
    delete attachmentValidationErrorState["size"];
    setAttachmentValidationError(attachmentValidationErrorState);
    return true;
  };

  const alertUploadError = () => {
    return Object.keys(attachmentValidationError).map((error, key) => (
      <InlineMessage key={key} messageType="error" message={attachmentValidationError[error]} />
    ));
  };

  if (error) {
    return <ErrorAlert componentName="AdminBulkUpload" />;
  }

  return (
    <Modal header="Bulk Upload" isOpen={true} onClose={() => history.push("/admin")}>
      <Column alignmentHorizontal="center" spacingInset="500">
        <Text type="p"> Upload CSV file to submit Feedback in bulk</Text>
        <UnorderedList>
          <ListItem>Upload a CSV UTF-8 file</ListItem>
          <ListItem>Don't use currency symbol for blocked revenue</ListItem>
          <ListItem>
            Refer{" "}
            <Link href={"/sample.csv"} download="sample.csv">
              sample.csv
            </Link>{" "}
            for file format
          </ListItem>
        </UnorderedList>
        <FileUploadButton inputId="file-uploader" label="Attachments" onChange={e => onUpload(e)} />
        {Object.keys(attachmentValidationError).length > 0 && alertUploadError()}
      </Column>
    </Modal>
  );
};

export default AdminBulkUpload;
