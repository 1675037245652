import {
  // Used in ATC
  CloudWatchRumProvider,
  // <MetricsSinks>
  // Used when your application also resides outside of ATC
  KatalMetricsSushiProvider,
  // Used in ATC and AMH
  TaktProvider,
  // <MetricsSinks>
  TelemetryClient,

  // <Plugins>
  monitorActiveTimeSpent,
  monitorAndReportErrors,
  monitorPageViews,
} from "@amzn/asx-ui-telemetry-client";
import { getEnvironment } from "./../util";

const config = {
  siteName: "SF",
  serviceName: "CLF",
  stage: getEnvironment(),
  sharedContext: {},
  region: "USAmazon",
} as const;

// Plugins to capture some base events across all of ASX
const plugins = [
  // Measures exact time spent by users actively interacting with the application
  monitorActiveTimeSpent,
  // Emits an event for each page view
  monitorPageViews,
  // Listens to uncaught errors and unhandled promise rejections and emits them
  monitorAndReportErrors,
];

const sinks = [
  {
    // Publishes to Katal Metrics
    // https://katal.amazon.dev/metrics/overview/
    provider: new KatalMetricsSushiProvider(config),
    plugins,
  },
  {
    // Publishes to the Ads console CloudWatch RUM instance
    // https://w.amazon.com/bin/view/SSPA/AX/ACPT/Telemetry/Documentation/CloudwatchRUMAccess/
    provider: new CloudWatchRumProvider(config),
    plugins,
  },
  {
    // Publishes to Takt using the Takt instance provided by Ads console and AMH
    // https://w.amazon.com/bin/view/SSPA/AX/ACPT/Telemetry/Takt
    provider: new TaktProvider(config),
    plugins,
  },
];

export const telemetryClient = new TelemetryClient({ metrics: sinks, config });

telemetryClient.start();
