import React, { useEffect, useState } from "react";
import { Text, Extender } from "@amzn/storm-ui";
import Loader from "../../components/Loader";
import { getFeedbacks } from "../../util/apiUtils";
import Logger from "../../util/logger";
import { createEMFObject } from "../../util/util";

const ThemeNameCellWithQuote = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [quoteList, setQuoteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNoFeedbackMessage, setShowNoFeedbackMessage] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const {
    data: { themeName, id },
  } = props;

  useEffect(() => {
    async function fetchFeedbacksData() {
      if (isOpen && !dataFetched) {
        Logger.sessionInfo("Themes Name more clicked", {
          emfLog: createEMFObject("ThemeNameExpanderClick", 1, "Count", {
            PageVisits: "FeedbackThemesVisits",
          }),
        });
        setIsLoading(true);
        setDataFetched(true);
        /* 
                make api call and fetch feedbacks
                Pick customerVerbatim from top 5 feedbacks and populate quoteList
                */
        const childfeedbacks = await getFeedbacks(`parentTheme:${id}`, 0, 5);
        if (childfeedbacks?.data?.results?.length) {
          setShowNoFeedbackMessage(false);
          const {
            data: { results },
          } = childfeedbacks;
          const customerVerbatimArr = [];
          results.forEach(feedback => {
            const {
              fields: { customerVerbatim },
            } = feedback;
            customerVerbatimArr.push(customerVerbatim);
          });
          setQuoteList(customerVerbatimArr);
        } else {
          setShowNoFeedbackMessage(true);
        }
        setIsLoading(false);
      }
    }
    fetchFeedbacksData();
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <Text>{themeName}</Text>
      {quoteList && (
        <Extender label={isOpen ? "less" : "more"} handleClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          {isLoading ? (
            <Loader />
          ) : (
            <ul>
              {quoteList.map(quote => (
                <li>{quote.slice(0, 60)}</li>
              ))}
            </ul>
          )}
          {showNoFeedbackMessage && <Text>No feedbbacks associated</Text>}
        </Extender>
      )}
    </>
  );
};

export default ThemeNameCellWithQuote;
