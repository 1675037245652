import { defaultTheme } from "@amzn/storm-ui";
import React from "react";
import styled from "styled-components";

import { filterPropsByPrefix, getHorizontalDirection, parseSpacing } from "../util/util";

const generateChildStyle = height => {
  return `flex: ${height === "fill" ? "1 !important" : undefined};
    flexShrink: ${height && height !== "fill" && height !== "auto" ? `0 !important` : undefined};
    height: ${
      height !== "fill" && height !== "fit" && height !== undefined && height !== null && height !== ""
        ? `${height}${typeof height === "number" ? "px" : ""} !important`
        : undefined
    }`;
};

export const Parent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  > * {
    box-sizing: border-box !important;
    margin: 0 0 ${props => props.verticalSpacing} 0 !important;
  }
  > *:last-child {
    margin: 0 !important;
  }
  ${props => {
    if (!Array.isArray(props.heights)) {
      return `> * {
          ${generateChildStyle(props.heights)}
        }`;
    }

    return props.heights.reduce((result, height, index) => {
      return `${result}
      > *:nth-child(${parseInt(index) + 1}){
        ${generateChildStyle(height)}
      }`;
    }, ``);
  }}
`;

class Column extends React.Component {
  getBackgroundColor() {
    const { backgroundColor, type } = this.props;
    if (backgroundColor === "primary") {
      return defaultTheme.palette.white;
    } else if (backgroundColor === "secondary") {
      return defaultTheme.palette.gray[10];
    } else {
      if (type === "fill" && !backgroundColor) {
        return defaultTheme.palette.gray[10];
      } else {
        return backgroundColor;
      }
    }
  }

  render() {
    const {
      alignmentHorizontal = "stretch",
      alignmentVertical = "top",
      children,
      className,
      height,
      heights,
      maxHeight,
      minHeight,
      maxWidth,
      minWidth,
      spacing = "medium",
      spacingInset,
      type,
      width,
    } = this.props;

    const spacingValues = parseSpacing(spacing);
    const verticalSpacing = spacingValues[0];
    const horizontalSpacing = spacingValues[1] || verticalSpacing;

    const ColumnStyles = {
      backgroundColor: this.getBackgroundColor(),
      alignItems: {
        left: "flex-start",
        right: "flex-end",
        center: "center",
        stretch: "stretch",
      }[getHorizontalDirection(alignmentHorizontal)],
      justifyContent: {
        top: "flex-start",
        bottom: "flex-end",
        center: "center",
        justify: "space-between",
      }[alignmentVertical],
      padding: parseSpacing(spacingInset).join(" "),
      height,
      maxHeight,
      minHeight,
      maxWidth,
      minWidth,
    };

    if (type === "outline") {
      ColumnStyles.borderRadius = "4px";
      ColumnStyles.border = `1px solid ${defaultTheme.palette.gray[300]}`;
    }

    if (width) {
      ColumnStyles.width = width;
    }

    return (
      <Parent
        className={className}
        style={ColumnStyles}
        heights={heights}
        horizontalSpacing={horizontalSpacing}
        verticalSpacing={verticalSpacing}
        {...filterPropsByPrefix(this.props, ["aria-", "data-"])}
      >
        {children}
      </Parent>
    );
  }
}

export default Column;
