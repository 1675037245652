export const API_END_POINT = {
  dev: "https://9k5gg5qdxc.execute-api.us-east-1.amazonaws.com/beta/",
  beta: "https://9k5gg5qdxc.execute-api.us-east-1.amazonaws.com/beta/",
  gamma: "https://iihemwdjp0.execute-api.us-east-1.amazonaws.com/gamma/",
  prod: "https://8jgdevl23m.execute-api.us-east-1.amazonaws.com/prod/",
};

export const TABLE_ROWS_COUNT = 50;
export const CEILING_BUCKET_SIZE = 1500;
export const DATA_COUNT = 1000;
export const PREV_2_YEARS = 730;
export const EMPTY_DATA = "-";
export const CHARACTER_LIMIT = 80;
export const revenueBuckets = [
  {
    name: "< 500,000",
    condition: value => value < 500000,
    sortOrder: 1,
  },
  {
    name: "500,000 - 1,000,000",
    condition: value => value >= 500000 && value < 1000000,
    sortOrder: 2,
  },
  {
    name: "1,000,000 - 5,000,000",
    condition: value => value >= 1000000 && value < 5000000,
    sortOrder: 3,
  },
  {
    name: "> 5, 000,000",
    condition: value => value >= 5000000,
    sortOrder: 4,
  },
];

export const PAGINATION_CONSTANT = [50, 100, 200, 300];

export const THEME_STATUS = ["New", "Reviewed", "On Roadmap", "In Progress", "Beta", "Launched"];

export const SOURCE = ["Sales AI", "ACF portal"];

export const THEME_PRIORITY = ["high", "medium", "low"];

export const WHY_IS_THIS_IMPORTANT_DROPDOWN_ITEMS = [
  "Product bug",
  "New feature request",
  "General customer sentiment",
  "Product launch feedback",
  "Revenue blocker",
];

export const IS_MAPPING_REVIEWER = "isMappingReviewer";

export const IS_ADMIN = "isAdmin";

export const IS_THEME_ADMIN = "isThemeAdmin";
export const INDEXED_DB_STORE = {
  feedbacksList: "allFeedbacks",
  lastUpdatedTimeAllFeedback: "lastUpdatedTimeAllFeedback",
  myFeedbacksList: "myFeedbacks",
  lastUpdatedTimeMyFeedback: "lastUpdatedTimeMyFeedback",
};
export const IT_TEST_USER = "svcacfuser";
