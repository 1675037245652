import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Divider, Button, TextButton, TextAreaFormGroup } from "@amzn/storm-ui";
import { Dropdown, DropdownItem } from "@amzn/storm-ui";
import { Form, FormRow } from "@amzn/storm-ui-column-form-kit";
import { useStore } from "../../../store";
import apiClient from "../../../util/apiClient";
import Logger from "../../../util/logger";
import Row from "../../../components/row";
import { getStaticData, createEMFObject, stringComparatorForArrayOfObjects } from "../../../util/util";
import endpoints from "../../../urlConfig";
import ErrorAlert from "../../../components/error-alert/ErrorAlert";

const CreateFeedbackCategory = props => {
  const [categoryInput, setCategoryInput] = useState("");
  const [notes, setNotes] = useState("");
  const [isError, setIsError] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const history = useHistory();
  const { mode } = props;
  const { updateStaticData } = useStore();
  const staticData = useStore(state => state.staticData);

  const userInfo = useStore(state => state.userInfo);
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [feedbackCategories, setFeedbackCategories] = useState([]);
  const { staticOperations, staticEndpoint } = endpoints;

  useEffect(() => {
    setFeedbackTypes(getStaticData("FEEDBACK_TYPE", "", staticData).sort());
    const categoriesList = staticData
      .filter(data => data.docType === "FEEDBACK_CATEGORY")
      .sort(stringComparatorForArrayOfObjects);
    setFeedbackCategories(categoriesList);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedType(selectedCategory.parent);
    setCategoryInput(selectedCategory.value);
  }, [selectedCategory]);

  const closeCreateModal = () => {
    history.push("/admin/categorization");
  };

  const validateSubmit = () => {
    return !selectedType || !categoryInput || !notes;
  };

  const validateEdit = () => {
    const isdisabled =
      !categoryInput ||
      !notes ||
      !selectedType ||
      (selectedCategory.value === categoryInput && selectedCategory.parent === selectedType);
    return isdisabled;
  };

  const createCategorySuccess = res => {
    Logger.sessionInfo(`${mode} Feedback Category successfull: ${categoryInput}`, {
      emfLog: createEMFObject(`${mode}FeedbackCategoryCounts`, 1, "Count"),
    });
    const params = {
      size: 10000,
    };
    apiClient
      .get(staticEndpoint, { params })
      .then(response => {
        updateStaticData(
          response?.data?.results?.map(stat => {
            return Object.assign(stat.fields, { id: stat.id });
          })
        );
        history.push("/admin/categorization");
      })
      .catch(error => {
        Logger.sessionError(`fetching staticdata failed ${error}`, {
          browserUrl: window.location.href,
        });
        setIsError(true);
      });
  };

  const handleEdit = () => {
    let submitObj = {};
    const {
      user: { email },
    } = userInfo;
    submitObj = {
      entityType: "feedbackCategory",
      operationType: "update",
      input: {
        id: selectedCategory.id,
        fields: {
          docType: "FEEDBACK_CATEGORY",
          parent: selectedType,
          value: categoryInput,
          updatedBy: email,
          notes: notes,
          entityValue: categoryInput,
          operationType: "update",
        },
      },
    };
    apiClient
      .post(staticOperations, submitObj)
      .then(createCategorySuccess)
      .catch(err => {
        Logger.sessionError(`Failed to create Feedback Category: ${categoryInput} error: ${err}`, {
          emfLog: createEMFObject(`UpdateFeedbackCategoryFailed`, 1, "Count", {
            FeedbackCategory: "UpdateFeedbackCategoryFailed",
          }),
        });
        setIsError(true);
      });
  };

  const handleSubmit = () => {
    let submitObj = {};
    const {
      user: { email },
    } = userInfo;
    submitObj = {
      entityType: "feedbackCategory",
      operationType: "create",
      input: {
        fields: {
          docType: "FEEDBACK_CATEGORY",
          parent: selectedType,
          value: categoryInput,
          updatedBy: email,
          notes: notes,
          entityValue: categoryInput,
          operationType: "create",
        },
      },
    };
    apiClient
      .post(staticOperations, submitObj)
      .then(createCategorySuccess)
      .catch(err => {
        Logger.sessionError(`Failed to create Feedback Category: ${categoryInput} error: ${err}`, {
          emfLog: createEMFObject(`CreateFeedbackCategoryFailed`, 1, "Count", {
            FeedbackCategory: "CreateFeedbackCategoryFailed",
          }),
        });
        setIsError(true);
      });
  };
  if (isError) {
    return <ErrorAlert componentName="CreateFeedbackCategory" />;
  }
  return (
    <Form id="createLabel">
      {mode !== "Create" && (
        <FormRow
          label="Select feedback category"
          isSectionHeader
          id="feedback-category-dropdown-row"
          controlComponents={[
            componentId => (
              <Dropdown
                onChange={val => setSelectedCategory(val)}
                selectedValue={selectedCategory}
                onOverrideLabel={(dropdownItem, value) => {
                  if (value.id) {
                    return dropdownItem;
                  }
                  return "Choose feedback category to update";
                }}
                error={!selectedCategory?.value}
              >
                {feedbackCategories.map((type, key) => {
                  console.log(type);
                  console.log(feedbackCategories);
                  return (
                    <DropdownItem key={key} value={type}>
                      {type.value}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            ),
          ]}
        />
      )}
      <FormRow
        label="Feedback type"
        isSectionHeader
        id="feedback-type-row"
        controlComponents={[
          componentId => (
            <Dropdown
              onChange={val => setSelectedType(val)}
              selectedValue={selectedType}
              onOverrideLabel={(dropdownItem, value) => {
                if (value) {
                  return dropdownItem;
                }
                return "Choose parent feedback type";
              }}
              error={!selectedType}
            >
              {feedbackTypes.map((type, key) => (
                <DropdownItem key={key} value={type}>
                  {type}
                </DropdownItem>
              ))}
            </Dropdown>
          ),
        ]}
      />
      <Divider />
      <FormRow
        label="Feedback category"
        isSectionHeader
        id="feedback-category-row"
        controlComponents={[
          componentId => (
            <TextAreaFormGroup
              placeholder="Create feedback category"
              value={categoryInput}
              onChange={event => {
                setCategoryInput(event.target.value);
              }}
              statusType={selectedType && !categoryInput ? "error" : undefined}
            />
          ),
        ]}
      />
      <Divider />
      <FormRow
        label="Remarks"
        isSectionHeader
        id="remarks-row"
        controlComponents={[
          componentId => (
            <TextAreaFormGroup
              placeholder="Remarks"
              value={notes}
              onChange={event => {
                setNotes(event.target.value);
              }}
              statusType={categoryInput && !notes ? "error" : undefined}
            />
          ),
        ]}
      />
      <Divider />
      <Row alignmentHorizontal="right">
        <TextButton onClick={closeCreateModal}>Cancel</TextButton>
        <Button
          disabled={mode === "Create" ? validateSubmit() : validateEdit()}
          primary={true}
          onClick={() => (mode === "Create" ? handleSubmit() : handleEdit())}
        >
          {`${mode} Feedback category`}
        </Button>
      </Row>
    </Form>
  );
};

export default CreateFeedbackCategory;
