import { Icon } from "@amzn/storm-ui";
import { copy } from "@amzn/storm-ui-icons";
import * as React from "react";

import Row from "../../../components/row";
import "../Admin.scss";

const TemplateLinkRenderer = props => {
  const { value } = props;

  const copyTemplateLink = () => {
    window.navigator.clipboard.writeText(value);
  };

  return (
    <Row widths="100px">
      <span className="showEllipsis">{value}</span>
      <Icon className="clickable" onClick={copyTemplateLink} type={copy} />
    </Row>
  );
};

export default TemplateLinkRenderer;
