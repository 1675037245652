import React, { useEffect, useState } from "react";

import { TabbedNavigation, TabbedNavigationItem } from "@amzn/storm-ui";
import Row from "../row";
import Logger from "../../util/logger";
import { useHistory, useLocation } from "react-router";
import { useStore } from "../../store";
import { IS_ADMIN, IS_MAPPING_REVIEWER } from "../../util/constants";

const TabHeader = props => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const history = useHistory();
  const location = useLocation();

  const { userPermissions } = useStore();
  console.log(userPermissions);
  useEffect(() => {
    if (userPermissions[IS_ADMIN] || userPermissions[IS_MAPPING_REVIEWER]) {
      setIsAdmin(true);
    }
    // eslint-disable-next-line
  }, [userPermissions]);

  // useEffect(()=>{
  //   if(activeTab){
  //     history.push("/" + activeTab);
  //     Logger.sessionInfo(`User navigated to tab ${activeTab}`, {
  //       browserUrl: window.location.href,
  //     });
  //   }
  // // eslint-disable-next-line
  // },[activeTab])

  useEffect(() => {
    const tab = getSelectedTab();
    setActiveTab(tab);
    // eslint-disable-next-line
  }, [location]);

  const tabs = [
    { label: "All feedback", value: "feature" },
    { label: "My submitted feedback", value: "feedback" },
    { label: "Feedback trends", value: "trends" },
    { label: "Admin view", value: "admin", isAdminView: true },
    { label: "Feedback themes", value: "themes" },
  ];

  const navigateToTab = tab => {
    setActiveTab(tab);
    history.push("/" + tab);
    Logger.sessionInfo(`User navigated to tab ${tab}`, {
      browserUrl: window.location.href,
    });
  };

  const getSelectedTab = tabName => {
    let path = window.location.pathname;
    path = path.split("/")[1];
    console.log(path);
    if (path === "list/feedback/my") {
      return "feedback";
    } else if (path === "list/feature/recent") {
      return "recentfeature";
    }
    return path ? path : "feature";
  };

  return (
    <Row spacingInset="none none none medium" alignmentVertical="center">
      <TabbedNavigation>
        {tabs.map(
          tab =>
            ((tab.isAdminView && isAdmin) || (!tab.isAdminView && !tab.isThemeView)) && (
              <TabbedNavigationItem
                key={tab.value}
                active={activeTab === tab.value}
                label={tab.label}
                onClick={() => navigateToTab(tab.value)}
              />
            )
        )}
      </TabbedNavigation>
    </Row>
  );
};

export default TabHeader;
