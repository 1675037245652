import { TelemetryClientInterface } from "../types";
import {
  withInstrumentation,
  withInstrumentationSync,
} from "./with-instrumentation";

export const WithInstrumentation = (
  telemetryClient: TelemetryClientInterface,
  operation: string
) => {
  return (
    _target: Object,
    _propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<any>
  ) => {
    const originalMethod = descriptor.value;
    descriptor.value = withInstrumentation(
      telemetryClient,
      operation,
      originalMethod
    );
    return descriptor;
  };
};

export const WithInstrumentationSync = (
  telemetryClient: TelemetryClientInterface,
  operation: string
) => {
  return (
    _target: Object,
    _propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<any>
  ) => {
    const originalMethod = descriptor.value;
    descriptor.value = withInstrumentationSync(
      telemetryClient,
      operation,
      originalMethod
    );
    return descriptor;
  };
};
